import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';
import { backdropAtom } from '../../../lib/backdrop';
import { fetch_one } from "../../../lib/v31lib";

import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import {
  Avatar,
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import {
  Button,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { SnackSaved } from '../snacks/Saved';

import { DiaryDay } from '../../../models/DiaryDay';
import { EatMoment as EatMmnt } from '../../../models/EatMoment';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  eatMoment: EatMmnt;
  diaryDay?: DiaryDay;
  refetch?: () => void;
  consent?: boolean;
};

export const EatMomentOverview: FunctionComponent<Props> = ({consent, open, setOpen, eatMoment, refetch}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const [currentBackdrop, setCurrentBackdrop] = useAtom(backdropAtom);
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();

  const [comment, setComment] = useState<string>('');
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);
  const [feThumbIds, setFeThumbIds] = useState<number[]>([]);
  const [thumb, setThumb] = useState<boolean>(false);

  // const [currentTab, setCurrentTab] = useState<string>((Object.values(login?.full_eat_groups || {}).length > 0 ? 'eat_diary.groups.short' : 'eat_diary.groups.everything'));

  const opacityCell = (label:string, field:any, opacity:number) => {
    return <Grid item xs={6} md={4} sx={{
      opacity: (!!field && field !== "/10" ? 1 : opacity)
    }}>
      <Typography variant="caption">{label}</Typography>
      <Typography>{field || '-'}</Typography>
    </Grid>
  };






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!refetch) refetch();
      queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id] });
      setSnackOpen(true);
    },
  });



  

  useEffect(() => {
    setFeThumbIds([]);
    setComment('');
    
    if (login?.type === "User::Coach" && (eatMoment.feedbacks || []).filter(ff => ff.user_id === login?.id).length > 0) {
      setComment((eatMoment.feedbacks || []).filter(ff => ff.user_id === login?.id)[0].comment);
    }

    (eatMoment.food_entries || []).forEach(entry => {
      if (!!entry.id && (entry.feedbacks || []).filter(ff => ((ff.user_id === login?.id) && ff.thumb)).length > 0) {
        setFeThumbIds([...feThumbIds, entry.id]);
      }
    });

    setTimeout(() => {setInited(true);}, 1000);
  }, [eatMoment]);
  // useEffect(() => {
  //   if (login?.type === "User::Coach" && !!inited) {
  //     let to = setTimeout(() => {
  //       mutationCustomAction.mutate({
  //         formData: {
  //           object: "eat_moment",
  //           class_action: "custom_api_give_feedback",
  //           id: eatMoment.id,
  //           handler_id: login?.id,
  //           comment: comment,
  //           practice_id: currentPractice?.id
  //         }
  //       });
  //     }, 1000);
  
  //     return () => {
  //       clearTimeout(to);
  //     }
  //   }
  // }, [comment]);
  useEffect(() => {
    if (login?.type === "User::Coach" && !!inited) {
      let to = setTimeout(() => {
        mutationCustomAction.mutate({
          formData: {
            object: "eat_moment",
            class_action: "custom_api_give_feedback",
            id: eatMoment.id,
            handler_id: login?.id,
            comment: comment,
            thumb: thumb,
            practice_id: currentPractice?.id
          }
        });
      }, 1000);

      return () => {
        clearTimeout(to);
      }
    }
  }, [comment, thumb]);

  if (login?.type === "User::Coach" && !consent) {
    return (<Base
      name="eat_moment_view"
      title=''
      fullWidth
      maxWidth="md"
      content={<>
        <Typography sx={{
          width: '100%',
          display: 'inline-block',
          fontStyle: 'italic',
          paddingLeft: 5,
          paddingBottom: 2,
          textAlign: 'justify',
          fontSize: '0.8rem',
          color: theme.palette.warning.main
        }}>{t("coach.consents.no_access")}</Typography>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />);
  };

  return (<>
    <Base
      name="eat_moment_view"
      title=''
      fullWidth
      maxWidth="md"
      content={<>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("client.models.eat_moments.title", "Gegevens over de maaltijd")}</Typography>
          </Grid>

          {opacityCell(t("client.models.eat_moments.entry_time", "Tijdstip"), eatMoment.given_entry, 1)}
          {opacityCell(t("coach.models.eat_moments.entry_time_actual", "Tijdstip van ingave"), DateTime.fromISO(eatMoment.entry_time_actual).toFormat("cccc DDD t"), 1)}
          {opacityCell(t("client.models.eat_moments.location", "Locatie"), eatMoment.location, 0.3)}

          {opacityCell(t("client.models.eat_moments.participants", "Wie nam deel aan de maaltijd"), eatMoment.participants, 0.3)}
          {opacityCell(t("client.models.eat_moments.activity", "Activiteit voorafgaand aan de maaltijd"), eatMoment.activity, 0.3)}
          {opacityCell(t("client.models.eat_moments.hunger", "Hongergevoel"), `${typeof(eatMoment.hunger) !== 'undefined' && eatMoment.hunger >= 0 ? (eatMoment.hunger || '') : ''}/10`, 0.3)}
          {opacityCell(t("client.models.eat_moments.complaints", "Klachten omtrent de maaltijd"), eatMoment.complaints, 0.3)}
          <Grid item xs={6} md={4} sx={{
            opacity: (typeof(eatMoment.feeling) !== 'undefined' && (eatMoment.feeling === 0 || eatMoment.feeling === 1 || eatMoment.feeling === 2 || eatMoment.feeling === 3) ? 1 : 0.3)
          }}>
            <Typography variant="caption">{t("client.models.eat_moments.feeling", "Gevoel na de maaltijd")}</Typography>
            {eatMoment.feeling === 0 && <Typography>{t("client.models.eat_moments.feeling_unhappy", "Niet tevreden")}</Typography>}
            {eatMoment.feeling === 1 && <Typography>{t("client.models.eat_moments.feeling_nothappy", "Licht ontevreden")}</Typography>}
            {eatMoment.feeling === 2 && <Typography>{t("client.models.eat_moments.feeling_happy", "Licht tevreden")}</Typography>}
            {eatMoment.feeling === 3 && <Typography>{t("client.models.eat_moments.feeling_veryhappy", "Tevreden")}</Typography>}
          </Grid>
          {opacityCell(t("client.models.eat_moments.comment", "Extra opmerkingen over de maaltijd"), eatMoment.comment, 0.3)}

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t("client.models.food_entries.title", "Gegevens over de voeding")}</Typography>
          </Grid>
          <Grid item xs={12}>
            {(eatMoment.food_entries || []).length < 1 && <Typography sx={{fontStyle: 'italic', textAlign: 'center'}}>{t("client.models.eat_moments.food_entries_empty", "Er zijn geen voedingslijnen")}</Typography>}
            {(eatMoment.food_entries || []).length > 0 && <Box sx={{
              maxHeight: '380px',
              overflow: 'auto'
            }}><List>
              {(eatMoment.food_entries || []).map((entry, i) => <ListItem>
                <ListItemIcon>
                  <Avatar
                    src={!!entry.get_item ? entry.get_item : !!entry.item ? URL.createObjectURL(entry.item) : undefined}
                    onClick={() => {
                      if (!!(!!entry.get_item ? entry.get_item : !!entry.item ? URL.createObjectURL(entry.item) : '')) {
                        setCurrentBackdrop(!!entry.get_item ? entry.get_item : !!entry.item ? URL.createObjectURL(entry.item) : undefined);
                      }
                    }}
                    sx={{
                      ...(!!(!!entry.get_item ? entry.get_item : !!entry.item ? URL.createObjectURL(entry.item) : '') ? {
                        cursor: 'pointer'
                      } : {})
                    }}
                  >
                    <BrunchDiningIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={entry.entry} secondary={entry.comment} />
                <ListItemSecondaryAction>
                  {login?.type === "User::Coach" && <IconButton onClick={(e) => {
                    e.preventDefault();

                    if (feThumbIds.indexOf(entry.id || 0) > -1) {
                      setFeThumbIds(feThumbIds.filter(fff => fff !== entry.id || 0));
                    } else {
                      setFeThumbIds([...feThumbIds, entry.id || 0]);
                    }

                    mutationCustomAction.mutate({
                      formData: {
                        object: "food_entry",
                        class_action: "custom_api_give_feedback",
                        id: entry.id,
                        handler_id: login?.id,
                        practice_id: currentPractice?.id,
                        thumb: feThumbIds.indexOf(entry.id || 0) < 0
                      }
                    });
                  }} sx={{marginLeft: 'auto'}}>
                    {feThumbIds.indexOf(entry.id || 0) > -1 && <ThumbUpAltIcon color="primary" />}
                    {feThumbIds.indexOf(entry.id || 0) < 0 && <ThumbUpAltIcon />}
                  </IconButton>}

                  {login?.type === "User::User" && (entry.feedbacks || []).filter(ff => ff.thumb).length > 0 && <IconButton onClick={(e) => {
                    e.preventDefault();
                  }} sx={{marginLeft: 'auto'}}>
                    <ThumbUpAltIcon color="primary" />
                  </IconButton>}
                </ListItemSecondaryAction>
              </ListItem>)}
            </List></Box>}
          </Grid>

          {((login?.type === "User::Coach") || (login?.type === "User::User" && (eatMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0)) && <Grid item xs={12}>
            <Divider />
          </Grid>}
          {((login?.type === "User::Coach") || (login?.type === "User::User" && (eatMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0)) && <Grid item xs={12}>
            <Typography variant="h5">{t("coach.models.eat_moments.comment", "Opmerking over het eetmoment")}</Typography>
          </Grid>}
          {login?.type === "User::Coach" && <Grid item xs={12}>
            <TextInput
              id="comment"
              // caption={t("client.models.eat_moments.comment")}
              multiline
              value={comment}
              onChange={(e) => {
                setComment(e);
              }}
            />
          </Grid>}
          {login?.type === "User::Coach" && <Grid item xs={12}>
            <Button
              onClick={(e) => {
                setComment('');
              }}
              disabled={comment.length < 1}
              contained
              size="small"
              id="empty"
              sx={{
                marginTop: 1
              }}
              label={t("coach.general.actions.empty", 'Leegmaken')}
            />
            <IconButton
              onClick={(e) => {
                setThumb(!thumb);
              }}
              sx={{
                marginTop: 1,
                marginLeft: 3
              }}
            >
              <ThumbUpAltIcon color={!!thumb ? 'primary' : 'inherit'} />
            </IconButton>
          </Grid>}
          {login?.type === "User::User" && (eatMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0 && <Grid item xs={12}>
            {eatMoment.feedbacks?.filter(ff => ff.comment.length > 0).map(feedback => <Typography><span style={{fontWeight: 'bold'}}>{feedback.username}</span>: {feedback.comment}</Typography>)}
          </Grid>}
        </Grid>

      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
    
    <SnackSaved open={snackOpen} setOpen={setSnackOpen} />
  </>);
}
