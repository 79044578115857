import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import {unregister} from './registerServiceWorker';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'jotai';

import i18next from './i18n';
import Background from './images/nutriportalbackground.png';

import {Main} from './pages/Main';
import {Loader} from './components/v2/Loader';

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "993f1d8406233c77c2eaf9db599f07f7Tz05NzgwOCxFPTE3NTc1NDM1MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
);

const { Settings } = require("luxon");

const queryClient = new QueryClient();
Settings.defaultLocale = "nl";

declare module '@mui/material/styles' {
  interface Palette {
    tertiary?: {main?: string};
    danger?: {main?: string};
    fancy_bg_img?: string;
  }
  interface PaletteOptions {
    tertiary?: {main: string};
    danger?: {main: string};
    lightgray?: string;
    transparant?: string;
    fancy_bg_img?: string;
  }
  interface ThemeOptions {
    drawer_width?: number;
  }
  interface PaletteColorOptions {
    light?: string;
    main?: string;
    dark?: string;
    contrastText?: string;
    fade?: string;
  }
};

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 700,

    h1: {
      fontSize: "1.75rem",
      fontFamily: 'Archivo',
      // color: 'rgb(16, 46, 86)'
    },
    h2: {
      fontSize: "1.6rem",
      fontFamily: 'Archivo',
      // color: 'rgb(16, 46, 86)'
    },
    h3: {
      fontSize: "1.35rem",
      fontFamily: 'Archivo',
      // color: 'rgb(16, 46, 86)'
    },
    h4: {
      fontSize: "1.2rem",
      fontFamily: 'Archivo',
      // color: 'rgb(16, 46, 86)'
    },
    h5: {
      fontSize: "1.1rem",
      fontFamily: 'Archivo',
    },
    h6: {
      // color: 'rgb(16, 46, 86)'
    },
    body1: {
      // color: 'rgb(16, 46, 86)'
    },
    body2: {
      // color: 'rgb(16, 46, 86)'
    },
    caption: {
      // color: 'rgb(16, 46, 86)'
      fontSize: "14px",
    }
  },
  palette: {
    primary: {
      light: '#abcb88',
      main: '#97BE6B',
      dark: '#69854a',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#be6b6b', // red
      light: '#cb8888',
      dark: '#854a4a',
      contrastText: '#FFFFFF'
    },
    tertiary: {
      main: '#DEBC5A'
    },
    warning: {
      main: '#E69E54',
      fade: 'rgba(230, 157, 84, 0.1)'
    },
    lightgray: "rgb(244,248,247)",
    transparant: "transparent",
    fancy_bg_img: `url(${Background})`,
    mode: 'light',
    background: {
      default: "#f8f8f8",
      // lighter: "#ffffff"
    },
  },
  shape: {
    borderRadius: 18
  },
  drawer_width: 240,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          fontFamily: 'Archivo',
          fontWeight: 400
        })
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: 'Archivo',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: 'Archivo',
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            fontFamily: 'Archivo',
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: 'Archivo',
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: 'Archivo',
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            fontSize: '14px'
          },
        },
      ]
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <React.Suspense fallback={(<span>Please wait...</span>)}>
    {/* <Provider> */}
        <Loader />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Main />
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
    {/* </Provider> */}
      </React.Suspense>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
