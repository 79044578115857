import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  Fab,
  Page,
  Typography
} from '../../../components/v2/styled';
import { FriendLinkDetail } from '../../../components/v2/dialogs/FriendLinkDetail';

import { FriendLink } from '../../../models/FriendLink';
import { Practice } from '../../../models/Practice';

import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {}

export const CoachFriendLinks: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [activeInvitation, setActiveInvitation] = useState<FriendLink>();

  let countInv = 0;
  let countOut = 0;
  let countOth = 0;
  let countYou = 0;





  const {
    data: invitations,
    isLoading: invitationsLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "friend_links", 'invitations', (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all_with_count<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'friend_link',
          fields: ['id', 'created_at', 'invited', 'inviter', 'accepted', 'denied', 'revoked', 'extra_data', 'ends_on'],
          sub_fields: {
            'invited': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active'],
            'inviter': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active']
          },
          order: "id DESC",
          per_page: 200,
          page: 0,
          filter: {
            search: '',
            advanced: {
              invited_id: (login?.practice_id || currentPractice?.id),
              accepted: 0,
              denied: 0
            }
          }
        },
        login
      ),
    select: (d) => {
      countInv = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: outgoingInvitations,
    isLoading: outgoingInvitationsLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "friend_links", 'outgoing_invitations', (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all_with_count<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'friend_link',
          fields: ['id', 'created_at', 'invited', 'inviter', 'accepted', 'denied', 'revoked', 'extra_data', 'ends_on'],
          sub_fields: {
            'invited': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active'],
            'inviter': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active']
          },
          order: "id DESC",
          per_page: 200,
          page: 0,
          filter: {
            search: '',
            advanced: {
              inviter_id: (login?.practice_id || currentPractice?.id),
              accepted: 0,
              denied: 0
            }
          }
        },
        login
      ),
    select: (d) => {
      countOut = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: friendsByOthers,
    isLoading: friendsByOthersLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "friend_links", 'by_others', (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all_with_count<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'friend_link',
          fields: ['id', 'created_at', 'invited', 'inviter', 'accepted', 'denied', 'revoked', 'extra_data', 'ends_on'],
          sub_fields: {
            'invited': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active'],
            'inviter': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active', 'get_friend_code']
          },
          order: "id DESC",
          per_page: 200,
          page: 0,
          filter: {
            search: '',
            advanced: {
              invited_id: (login?.practice_id || currentPractice?.id),
              handled: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      countOth = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: friendsByYou,
    isLoading: friendsByYouLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "friend_links", 'by_me', (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all_with_count<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'friend_link',
          fields: ['id', 'created_at', 'invited', 'inviter', 'accepted', 'denied', 'revoked', 'extra_data', 'ends_on'],
          sub_fields: {
            'invited': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active'],
            'inviter': ['id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations', 'locations|true_active']
          },
          order: "id DESC",
          per_page: 200,
          page: 0,
          filter: {
            search: '',
            advanced: {
              inviter_id: (login?.practice_id || currentPractice?.id),
              handled: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      countYou = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });







  const fields = [
    'id', 'get_friend_code'
  ];

  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["friend_link_settings", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });











  const nonPhysCard = (practice:Practice, showLocations:boolean, invitation?: FriendLink, onClick?: () => void) => {
    return (<Box sx={{
      padding: 4,
      paddingLeft: {
        xs: '32px',
        md: '232px'
      },
      paddingTop: {
        xs: !!invitation ? '164px' : '132px',
        md: '32px'
      },
      position: 'relative',
      borderRadius: '16px',
      cursor: 'pointer',
      border: '1px solid transparent',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'white'
    }} onClick={() => {
      if (!!onClick) onClick();
    }}>
      {!!invitation && <Box sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: '32px',
        width: '100%',
        lineHeight: '32px',
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '16px 16px 0px 0px'
      }}>
        {!invitation.accepted && <Typography sx={{
          lineHeight: '32px'
        }}>{t("coach.friend_links.invitation_status.open")}</Typography>}
        {!!invitation.accepted && !invitation.ends_on && <Typography sx={{
          lineHeight: '32px'
        }}>{t("coach.friend_links.invitation_status.accepted_unlimited")}</Typography>}
        {!!invitation.accepted && !!invitation.ends_on && <Typography sx={{
          lineHeight: '32px'
        }}>{t("coach.friend_links.invitation_status.accepted_limited", {date: DateTime.fromISO(invitation.ends_on).toFormat("dd/LL/y")})}</Typography>}
      </Box>}
      <Box sx={{
        width: {
          xs: '100%',
          md: '136px'
        },
        height: {
          xs: 84,
          md: "calc(100% - 64px)"
        },
        position: 'absolute',
        left: {
          xs: 0,
          md: 32
        },
        top: {
          xs: !!invitation ? 40 : 8,
          md: 32
        },
        backgroundImage: `url(${practice.get_medium})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }} />
      {
        !!invitation && 
        !!invitation.accepted && 
        invitation.inviter?.id !== (currentPractice?.id || login?.practice_id) && 
        (invitations || []).filter(fff => !!fff.invited_id && fff.invited_id === invitation.inviter?.id).length < 1 && 
        (friendsByYou || []).filter(fff => !!fff.invited_id && fff.invited_id === invitation.inviter?.id).length < 1 && 
      <Button
        id="return_link"
        label={t("coach.friend_links.add_return_link")}
        contained
        onClick={() => {
          navigate("/friend_link/new", {state: {
            friendCode: invitation.inviter?.get_friend_code,
            practice_id: invitation.inviter?.id
          }});
        }}
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          width: {
            xs: 'calc(100% - 16px)',
            md: '184px'
          }
        }}
      />}
      <Box sx={{
        height: {
          xs: '1px',
          md: '100%'
        },
        position: 'absolute',
        left: {
          xs: 0,
          md: 200
        },
        top: {
          xs: !!invitation ? 132 : 100,
          md: 0
        },
        width: {
          xs: '100%',
          md: '1px'
        },
        borderTop: {
          md: 'none',
          xs: '1px solid transparent'
        },
        borderLeft: {
          xs: 'none',
          md: '1px solid transparent'
        },
        borderColor: {
          xs: theme.palette.primary.main,
          md: theme.palette.primary.main
        },
      }} />

      <Typography sx={{
        fontWeight: 'bold'
      }}>{practice.name}</Typography>
      <Typography>{(practice.coach_full_list || []).map(coach => <Box sx={{
        width: 'auto',
        display: 'inline-block',
        border: '1px solid transparent',
        // borderColor: theme.palette.primary.main,
        borderRadius: '32px',
        padding: 0,
        position: 'relative',
        paddingLeft: '64px',
        paddingRight: '16px',
        height: '48px',
        marginRight: 4,
        marginTop: 1,
        marginBottom: 1
      }}>
        <Avatar src={coach.get_medium} sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '48px',
          width: '48px'
        }} />
        <Typography sx={{
          lineHeight: {
            xs: '24px',
            md: '48px'
          }
        }}>{[coach.first_name, coach.last_name].join(" ")}</Typography>
      </Box>)}</Typography>
      {!!showLocations && <Typography>{(practice.locations_true_active || []).filter(fff => !!fff.zip && !!fff.city).map(lll => [lll.zip, lll.city].join(' ')).filter((value, index, array) => array.indexOf(value) === index).join(", ")}</Typography>}
      {/* <Typography>{(practice.public_skill_ids || []).map(sss => (((coachSkills || []).filter(fff => fff.id === sss) || [])[0] || {}).name).sort().map(skill => <Chip
        color="primary"
        size="small"
        variant="outlined"
        label={skill}
        sx={{
          marginRight: 0.5,
          marginBottom: 0.5,
          marginTop: 0.5
        }}
      />)}</Typography> */}
      {!!(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] && <Typography sx={{
        paddingLeft: 2,
        marginTop: 2,
        borderLeft: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        color: 'grey',
        fontStyle: 'italic'
      }}>{(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>}
    </Box>);
  }
  
  return <Page>
    {!!login?.is_super && <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3">{t("coach.friend_links.title")}</Typography>
                <Typography variant="body1">{t("coach.friend_links.description")}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3">{t("coach.friend_links.friend_code_title")}</Typography>
                <Typography variant="body1" sx={{fontSize: 56}}>{extendedPracticeTemp?.get_friend_code}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>



        {(invitations || []).length > 0 && <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("coach.friend_links.open_invitations")} ({countInv})</Typography>
                <Divider />
                {invitationsLoading && <CircularProgress />}
                {(invitations || []).map(invitation => <Grid item xs={12} sx={{marginTop: 2}}>
                  {!!invitation.inviter && nonPhysCard(invitation.inviter, true, invitation, () => {
                    setActiveInvitation({
                      ...invitation,
                      ends_on: !!invitation.ends_on ? DateTime.fromISO(invitation.ends_on).toFormat("dd/LL/y") : undefined
                    });
                    setDetailOpen(true);
                  })}
                </Grid>)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>}

        {(outgoingInvitations || []).length > 0 && <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("coach.friend_links.outgoing_invitations")} ({countOut})</Typography>
                <Divider />
                {outgoingInvitationsLoading && <CircularProgress />}
                {(outgoingInvitations || []).map(invitation => <Grid item xs={12} sx={{marginTop: 2}}>
                  {!!invitation.invited && nonPhysCard(invitation.invited, true, invitation, () => {
                    navigate(`/friend_link/${invitation.id}`);
                  })}
                </Grid>)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>}

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("coach.friend_links.friends_by_you")} ({countYou})</Typography>
                <Divider />
                {friendsByYouLoading && <CircularProgress />}
                {(friendsByYou || []).map(invitation => <Grid item xs={12} sx={{marginTop: 2}}>
                  {!!invitation.invited && nonPhysCard(invitation.invited, true, invitation, () => {
                    setActiveInvitation({
                      ...invitation,
                      ends_on: !!invitation.ends_on ? DateTime.fromISO(invitation.ends_on).toFormat("dd/LL/y") : undefined
                    });
                    setDetailOpen(true);
                  })}
                </Grid>)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("coach.friend_links.friends_by_others")} ({countOth})</Typography>
                <Divider />
                {friendsByOthersLoading && <CircularProgress />}
                {(friendsByOthers || []).map(invitation => <Grid item xs={12} sx={{marginTop: 2}}>
                  {!!invitation.inviter && nonPhysCard(invitation.inviter, true, invitation, () => {
                    setActiveInvitation({
                      ...invitation,
                      ends_on: !!invitation.ends_on ? DateTime.fromISO(invitation.ends_on).toFormat("dd/LL/y") : undefined
                    });
                    setDetailOpen(true);
                  })}
                </Grid>)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Fab
        onClick={() => {
          navigate("/friend_link/new");
        }}
      />
    </>}

    {!!activeInvitation && <FriendLinkDetail
      friendLink={activeInvitation}
      open={detailOpen}
      setOpen={setDetailOpen}
    />}
  </Page>;
}

