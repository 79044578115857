import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';
// import { backdropAtom } from '../../../lib/backdrop';
import { fetch_one } from "../../../lib/v31lib";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FlakyIcon from '@mui/icons-material/Flaky';

import {
  Avatar,
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';

import {
  Button,
  DatePicker,
  IconButton,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { FriendLink } from '../../../models/FriendLink';
import { Practice } from '../../../models/Practice';

import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  friendLink: FriendLink;
  refetch?: () => void;
};

export const FriendLinkDetail: FunctionComponent<Props> = ({open, setOpen, friendLink, refetch}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();

  const [comment, setComment] = useState<string>('');
  const [thumb, setThumb] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);

  const asInviter = friendLink.inviter?.id === (currentPractice?.id || login?.practice_id);






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      navigate("/friend_links");
      setOpen(false);
    },
  });







  const nonPhysCard = (practice:Practice, showLocations:boolean) => {
    return (<Box sx={{
      padding: 4,
      paddingLeft: {
        xs: '32px',
        md: '232px'
      },
      paddingTop: {
        xs: '132px',
        md: '32px'
      },
      position: 'relative',
      borderRadius: '16px',
      cursor: 'pointer',
      border: '1px solid transparent',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'white'
    }} onClick={() => {
      // setSelectedPractice(practice);
    }}>
      <Box sx={{
        width: {
          xs: '100%',
          md: '136px'
        },
        height: {
          xs: 84,
          md: "calc(100% - 64px)"
        },
        position: 'absolute',
        left: {
          xs: 0,
          md: 32
        },
        top: {
          xs: 8,
          md: 32
        },
        backgroundImage: `url(${practice.get_medium})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }} />
      <Box sx={{
        height: {
          xs: '1px',
          md: '100%'
        },
        position: 'absolute',
        left: {
          xs: 0,
          md: 200
        },
        top: {
          xs: 100,
          md: 0
        },
        width: {
          xs: '100%',
          md: '1px'
        },
        borderTop: {
          md: 'none',
          xs: '1px solid transparent'
        },
        borderLeft: {
          xs: 'none',
          md: '1px solid transparent'
        },
        borderColor: {
          xs: theme.palette.primary.main,
          md: theme.palette.primary.main
        },
      }} />

      <Typography sx={{
        fontWeight: 'bold'
      }}>{practice.name}</Typography>
      <Typography>{(practice.coach_full_list || []).map(coach => <Box sx={{
        width: 'auto',
        display: 'inline-block',
        border: '1px solid transparent',
        // borderColor: theme.palette.primary.main,
        borderRadius: '32px',
        padding: 0,
        position: 'relative',
        paddingLeft: '64px',
        paddingRight: '16px',
        height: '48px',
        marginRight: 4,
        marginTop: 1,
        marginBottom: 1
      }}>
        <Avatar src={coach.get_medium} sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '48px',
          width: '48px'
        }} />
        <Typography sx={{
          lineHeight: {
            xs: '24px',
            md: '48px'
          }
        }}>{[coach.first_name, coach.last_name].join(" ")}</Typography>
      </Box>)}</Typography>
      {!!showLocations && <Typography>{(practice.locations_true_active || []).filter(fff => !!fff.zip && !!fff.city).map(lll => [lll.zip, lll.city].join(' ')).filter((value, index, array) => array.indexOf(value) === index).join(", ")}</Typography>}
      {/* <Typography>{(practice.public_skill_ids || []).map(sss => (((coachSkills || []).filter(fff => fff.id === sss) || [])[0] || {}).name).sort().map(skill => <Chip
        color="primary"
        size="small"
        variant="outlined"
        label={skill}
        sx={{
          marginRight: 0.5,
          marginBottom: 0.5,
          marginTop: 0.5
        }}
      />)}</Typography> */}
      {!!(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] && <Typography sx={{
        paddingLeft: 2,
        marginTop: 2,
        borderLeft: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        color: 'grey',
        fontStyle: 'italic'
      }}>{(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>}
    </Box>);
  }

  useEffect(() => {
    if (!open) {
      setInited(false);
    }
  }, [open]);

  return (<>
    <Base
      name="friendlink_detail"
      title={t("coach.friend_links.invitation.title")}
      fullWidth
      maxWidth="md"
      content={<>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            {!!(!!asInviter ? friendLink?.invited : friendLink?.inviter) && nonPhysCard(!!asInviter ? friendLink?.invited : friendLink?.inviter, true)}
          </Grid>
          <Grid item xs={12} sx={{marginTop: 4}}>
            <Typography variant="h5">{t("friend_links.headings.rights")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "32px !important"}}>
            <Typography variant="subtitle1">{t("friend_links.headings.nutri_mail_recipient")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.nutri_mail_recipient")}
              value={true}
              onChange={() => {}}
            />
          </Grid>


          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.agenda")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.agenda.enabled")}
              value={!!friendLink?.extra_data?.rights?.agenda?.enabled}
              onChange={(e) => {}}
            />
          </Grid>
          {!!friendLink?.extra_data?.rights?.agenda?.enabled && <Grid item xs={12} sx={{paddingLeft: "48px !important", marginTop: 2}}>
            <Grid container spacing={1}>
              <Grid item xs={9} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main
              }}>
                <Typography sx={{
                  fontWeight: 'bold'
                }}>{t("friend_links.rights.agenda.locations.location_name")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <DoNotDisturbIcon sx={{color: theme.palette.secondary.main}} />
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <FlakyIcon sx={{color: theme.palette.warning.main}} />
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <CheckCircleIcon sx={{color: theme.palette.primary.main}} />
              </Grid>
              
              {(friendLink?.inviter?.locations || []).map(location => <>
                <Grid item xs={9} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main
                }}>
                  <Typography sx={{
                    ...(!location.enabled ? {
                      color: 'lightgrey',
                      textDecoration: 'line-through'
                    } : {})
                  }}>{location.name}</Typography>
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: !(friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] ? 'lightgrey' : 'transparent'
                }}>
                  <DoNotDisturbIcon sx={{
                    color: (!(friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] ? theme.palette.secondary.main : 'grey'),
                    // cursor: 'pointer'
                  }} onClick={() => {}} />
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: (friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 1 ? 'lightgrey' : 'transparent'
                }}>
                  <FlakyIcon sx={{
                    color: ((friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 1 ? theme.palette.warning.main : 'grey'),
                    // cursor: 'pointer'
                  }} onClick={() => {}} />
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderRight: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: (friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 2 ? 'lightgrey' : 'transparent'
                }}>
                  <CheckCircleIcon sx={{
                    color: ((friendLink?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 2 ? theme.palette.primary.main : 'grey'),
                    // cursor: 'pointer'
                  }} onClick={() => {}} />
                </Grid>
              </>)}
            </Grid>
          </Grid>}


          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.workshops")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.workshops.enabled")}
              value={!!friendLink?.extra_data?.rights?.workshops?.enabled}
              onChange={(e) => {}}
            />
          </Grid>


          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.client_sharing")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Grid container spacing={1}>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <DoNotDisturbIcon sx={{
                  color: (!friendLink?.extra_data?.rights?.client_sharing?.enabled ? theme.palette.secondary.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!friendLink?.extra_data?.rights?.client_sharing?.enabled ? theme.palette.secondary.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}}>{t("friend_links.rights.client_sharing_disabled")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <FlakyIcon sx={{
                  color: (!!friendLink?.extra_data?.rights?.client_sharing?.enabled && friendLink?.extra_data?.rights?.client_sharing?.mode === 'whitelist' ? theme.palette.warning.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!!friendLink?.extra_data?.rights?.client_sharing?.enabled && friendLink?.extra_data?.rights?.client_sharing?.mode === 'whitelist' ? theme.palette.warning.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}}>{t("friend_links.rights.client_sharing_whitelist")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <CheckCircleIcon sx={{
                  color: (!!friendLink?.extra_data?.rights?.client_sharing?.enabled && friendLink?.extra_data?.rights?.client_sharing?.mode === 'full' ? theme.palette.primary.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!!friendLink?.extra_data?.rights?.client_sharing?.enabled && friendLink?.extra_data?.rights?.client_sharing?.mode === 'full' ? theme.palette.primary.main : 'grey'),
                  // cursor: 'pointer'
                }} onClick={() => {}}>{t("friend_links.rights.client_sharing_full")}</Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.appointment")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.appointment.enabled")}
              value={!!friendLink?.extra_data?.rights?.appointment?.enabled}
              onChange={(e) => {}}
            />
          </Grid>


          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.timing")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.timing.unlimited")}
              value={!friendLink?.ends_on}
              onChange={(e) => {}}
            />
          </Grid>
          {!!friendLink?.ends_on && <Grid item xs={6}>
            <TextInput
              disabled
              caption={t("friend_links.rights.timing.not_unlimited")}
              onChange={(e) => {}}
              value={friendLink?.ends_on || ''}
              id="ends_on"
            />
          </Grid>}









          <Grid item xs={12} sx={{marginTop: 2}}>
            {!!asInviter && !mutationSave.isPending && <Button
              label={t("shared.buttons.revoke")}
              id='revoke'
              contained
              destroyBtn
              onClick={(v) => {
                mutationSave.mutate({
                  formData: {
                    object: 'friend_link',
                    handler_id: login?.id,
                    fields: ['id'],
                    ob: {
                      id: friendLink.id,
                      revoked: 1
                    }
                  },
                });
              }}
              startIcon={<DoDisturbIcon />}
              sx={{marginBottom: 1, marginTop: 1}}
            />}
            
            {!asInviter && !!friendLink.accepted && !mutationSave.isPending && <Button
              label={t("shared.buttons.deny")}
              id='deny'
              contained
              destroyBtn
              onClick={(v) => {
                mutationSave.mutate({
                  formData: {
                    object: 'friend_link',
                    handler_id: login?.id,
                    fields: ['id'],
                    ob: {
                      id: friendLink.id,
                      denied: 1,
                      accepted: 0
                    }
                  },
                });
              }}
              startIcon={<DoDisturbIcon />}
              sx={{marginBottom: 1, marginTop: 1}}
            />}
            {!asInviter && (!friendLink.accepted && !friendLink.denied) && <>
              {!mutationSave.isPending && <Button
                label={t("shared.buttons.accept")}
                id='accept'
                contained
                onClick={(v) => {
                  mutationSave.mutate({
                    formData: {
                      object: 'friend_link',
                      handler_id: login?.id,
                      fields: ['id'],
                      ob: {
                        id: friendLink.id,
                        accepted: 1
                      }
                    },
                  });
                }}
                startIcon={<CheckCircleOutlineIcon />}
                sx={{marginBottom: 1, marginTop: 1}}
              />}
              {!mutationSave.isPending && <Button
                label={t("shared.buttons.deny")}
                id='deny'
                onClick={(v) => {
                  mutationSave.mutate({
                    formData: {
                      object: 'friend_link',
                      handler_id: login?.id,
                      fields: ['id'],
                      ob: {
                        id: friendLink.id,
                        denied: 1
                      }
                    },
                  });
                }}
                startIcon={<DoDisturbIcon />}
                sx={{marginBottom: 1, marginTop: 1, marginLeft: {xs: 0, md: 1}}}
              />}
            </>}
          </Grid>

        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </>);
}
