import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all_with_count } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TextsmsIcon from '@mui/icons-material/Textsms';

// import {
//   Box,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Typography
} from '../../../../components/v2/styled';
import { CoachEditAdvice } from '../../../../components/v2/dialogs/CoachEditAdvice';
import { SnackDestroyed } from '../../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../../components/v2/snacks/Saved';

import { Advice } from '../../../../models/Advice';
import { User } from '../../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  user: User;
}

export const CoachUserIntakeAdvices: FunctionComponent<Props> = ({user}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const [newAdvice, setNewAdvice] = useState<Advice>({attachments: []});
  const [clonedAdvice, setClonedAdvice] = useState<Advice>({attachments: []});
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "advices", (login?.practice_id || currentPractice?.id), {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Advice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'advice',
          fields: [
            'id', 'advice_date', 'weight', 'advice_uploads|count', 'feedback_goals', 'feedback_education', 'feedback_deals', 'created_at',
            'updated_at', 'creator|id/full_name', 'editor|id/full_name', 'comment', 'deals', 'goals', 'education', 'weight', 'waist', 'attachments'
          ],
          page: page,
          order: order,
          filter: {
            search: '',
            advanced: {
              user_id: user?.id,
              practice_id: currentPractice?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Advice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      setDestroyedOpen(true);
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Advice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEditOpen(false);
      objectsRefetch();
      setSavedOpen(true);
    },
  });






  


  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'advice_date', headerName: t("coach.models.advices.advice_date", "Datum"), width: 240, renderCell: params => <>
      <Dt d={params.row.advice_date} f="cccc DDD" />
    </> },
    { field: 'weight', headerName: t("coach.models.advices.weight", "Gewicht"), width: 120 },
    { field: 'advice_uploads_count', headerName: t("coach.models.advices.uploads", "Uploads"), sortable: false, width: 120, renderCell: params => <>
      {params.row.advice_uploads_count > 0 && <Typography sx={{lineHeight: '52px'}}><AttachmentIcon sx={{marginBottom: -0.5, marginRight: 0.5}} /> {params.row.advice_uploads_count}</Typography>}
    </> },
    { field: 'feedback_count', headerName: t("coach.models.advices.feedback", "Feedback"), sortable: false, width: 120, renderCell: params => <>
      {((!!params.row.feedback_goals ? 1 : 0) + (!!params.row.feedback_education ? 1 : 0) + (!!params.row.feedback_deals ? 1 : 0)) > 0 && <Typography sx={{lineHeight: '52px'}}><TextsmsIcon sx={{marginBottom: -1, marginRight: 0.5}} /> {((!!params.row.feedback_goals ? 1 : 0) + (!!params.row.feedback_education ? 1 : 0) + (!!params.row.feedback_deals ? 1 : 0))}</Typography>}
    </> },
  ];
  
  return <>
    <DataGrid
      action_count={2}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" onClick={() => {
            setNewAdvice({
              ...params.row,
              advice_date: DateTime.fromFormat(params.row.advice_date, "y-LL-dd").toFormat("dd/LL/y")
            });
            setEditOpen(true);
          }}>
            <EditIcon />
          </IconButton>
          <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'advice',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        // name: [u.last_name, u.first_name].join(", "),
        // enabled: !!u.enabled && !!u.consent_status?.enabled
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <Fab
      onClick={() => {
        setClonedAdvice({
          ...((objects || [])[0] || {}),
          attachments: [],
          advice_date: DateTime.now().toFormat("dd/LL/y"),
          weight: undefined,
          waist: undefined,
          feedback_deals: undefined,
          feedback_education: undefined,
          feedback_goals: undefined,
          id: undefined,
          cloned: true
        });
        if (login?.settings?.nutri_advice?.start_cloned) {
          setNewAdvice({
            ...((objects || [])[0] || {}),
            attachments: [],
            advice_date: DateTime.now().toFormat("dd/LL/y"),
            weight: undefined,
            waist: undefined,
            feedback_deals: undefined,
            feedback_education: undefined,
            feedback_goals: undefined,
            id: undefined,
            cloned: true
          });
        } else {
          setNewAdvice({
            attachments: [],
            advice_date: DateTime.now().toFormat("dd/LL/y")
          });
        }
        setEditOpen(true);
      }}
    />

    <CoachEditAdvice
      open={editOpen}
      setOpen={setEditOpen}
      advice={newAdvice}
      clonedAdvice={clonedAdvice}
      setAdvice={setNewAdvice}
      loading={mutationSave.isPending}
      onClick={() => {
        let formData = new FormData();
        formData.append('object', 'advice');
        formData.append('handler_id', (login?.id || "").toString());
        formData.append('fields[]', 'id');
        if (!!newAdvice.id) formData.append('ob[id]', newAdvice.id.toString());
        if (!!newAdvice.id) formData.append('id', newAdvice.id.toString());
        // mainObject
        formData.append('ob[user_id]', (user?.id || "").toString());
        formData.append('ob[practice_id]', (currentPractice?.id || "").toString());
        formData.append('ob[comment]', newAdvice?.comment || '');
        formData.append('ob[deals]', newAdvice?.deals || '');
        formData.append('ob[goals]', newAdvice?.goals || '');
        formData.append('ob[education]', newAdvice?.education || '');
        formData.append('ob[advice_date]', newAdvice?.advice_date || '');
        formData.append('ob[weight]', newAdvice?.weight || '');
        formData.append('ob[waist]', newAdvice?.waist || '');

        if (newAdvice.items) {
          newAdvice.items.forEach(item => {
            formData.append(`ob[items][]`, item);
          });
        }
        if (newAdvice.items_private) {
          newAdvice.items_private.forEach(item => {
            formData.append(`ob[items_private][]`, item);
          });
        }
        if (newAdvice.attachments) {
          newAdvice.attachments.forEach(item => {
            formData.append(`ob[attachments][][id]`, item.id?.toString() || '');
          });
        }

        mutationSave.mutate({formData: formData});
      }}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </>;
}

