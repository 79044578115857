import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  Tooltip
} from '@mui/material';

import {
  Button,
  Editor,
  FileDrop,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';

import { NutriMail } from '../../../models/NutriMail';

const { DateTime } = require("luxon");

type Props = {}

export const ClientEditNutriMail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  const { state } = useLocation();

  // !!state && !!state.mail => draft
  // state.reply => reply

  const [replyingTo, setReplyingTo] = useState<NutriMail>();
  const [currentMail, setCurrentMail] = useState<NutriMail>({
    //@ts-ignore
    body: !!state && !!state.reply ? `<p><br/><p>---<br/><b>Van</b>: ${state.reply.from.full_name}<br/><b>Aan</b>: ${(state.reply.nutri_mail_recipients || []).map(mm => mm.recipient?.full_name || '').filter(fff => !!fff).join("; ")}<br/><b>Tijd</b>: ${DateTime.fromISO(state.reply.created_at).toFormat("DDD t")}<br/></p>${state.reply.body}` : `<p><br/></p>${login?.settings && login?.settings.nutri_mail && login?.settings.nutri_mail.signature ? login?.settings.nutri_mail.signature : ''}`
  });
  const [items, setItems] = useState<File[]>([]);
  const [savedItems, setSavedItems] = useState<{
    id: number;
    item_file_name: string;
    get_item: string;
  }[]>([]);
  const [skippedFirstSave, setSkippedFirstSave] = useState<boolean>(false);
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["practice_coaches", login?.id],
    queryFn: () =>
      fetch_one<{custom_result: {
        [z:string]: {
          isDisabled: boolean;
          name: string;
          id: number;
        }[]
      }}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_all_coaches',
          id: login?.id
        },
        login
      ),
    select: (d) => {
      // mailCount = d.count;
      return d.custom_result;
    },
    enabled: !!login,
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NutriMail
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();

      if (!!variables.extra?.goback) {
        navigate("/inbox");
      } else {
        setCurrentMail({
          ...currentMail,
          ...data.custom_result.mail
        });
        if (data.custom_result.mail.nutri_mail_attachments) {
          setItems([]);
          setSavedItems(data.custom_result.mail.nutri_mail_attachments);
        }
      }
    },
  });
  const mutationDelete = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NutriMail
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/inbox");
    },
  });








  const saveMail = (goback?: boolean, send?: boolean) => {
    if (!!currentMail) {
      let data = new FormData();
      data.append('object', 'nutri_mail');
      data.append('class_action', 'custom_api_save_draft');
      if (!!currentMail) data.append('id', currentMail.id?.toString() || '');
      data.append('nutri_mail[to_id]', currentMail.to_id?.toString() || '');
      data.append('nutri_mail[subject]', currentMail.subject || '');
      data.append('nutri_mail[body]', currentMail.body || '');
      if (items.length > 0) {
        items.forEach((file, index) => {
          data.append(`item${index}`, file);
        });
      }
      if (savedItems.length > 0) {
        savedItems.forEach((file, index) => {
          data.append(`existing_file_ids[]`, file.id.toString());
        });
      }
      data.append('send_email', !!currentMail.send_email ? '1' : '0');
      if (!!send) data.append('send', '1');
      if (!!replyingTo) data.append('reply_id', replyingTo.id?.toString() || '');
      [
        'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
        'nutri_mail_attachments|count', 'nutri_mail_attachments'
      ].forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      ['id', 'full_name'].forEach((word, index) => {
        data.append(`sub_fields[to][]`, word);
      });
      ['id', 'full_name'].forEach((word, index) => {
        data.append(`sub_fields[from][]`, word);
      });
      ['id', 'get_item', 'item_file_name'].forEach((word, index) => {
        data.append(`sub_fields[nutri_mail_attachments][]`, word);
      });

      if (!!skippedFirstSave) {
        mutationCustomAction.mutate({formData: data, extra: {goback: !!goback}});
      } else {
        setSkippedFirstSave(true);
      }
    }
  }

  useEffect(() => {
    if (!!state && !!state.reply) {
      setReplyingTo(state.reply);
      setCurrentMail({
        ...currentMail,
        to_id: state.reply.from?.id,
        subject: `RE: ${state.reply.subject}`
      });
    } else {
      setReplyingTo(undefined);
    }
  }, [!!state && !!state.reply]);
  useEffect(() => {
    if (!!state && !!state.mail) {
      setFlickerEditor(true);
      setCurrentMail(state.mail);
      if (state.mail.nutri_mail_attachments) {
        setItems([]);
        setSavedItems(state.mail.nutri_mail_attachments);
      }
      setTimeout(() => {setFlickerEditor(false);}, 200);
    } else {
      
    }
  }, [!!state && !!state.mail]);
  useEffect(() => {
    let to = setTimeout(() => {
      saveMail();
    }, 1000);

    return () => {
      clearTimeout(to);
    }
  }, [currentMail?.body]);
  
  return <Page sx={{padding: {xs: 0, md: 3}}}>
    <Grid container spacing={1}>

      {!replyingTo && <Grid item xs={12}>
        <Select
          fullWidth
          caption={t("shared.nutri_mail.recipient", "Ontvanger")}
          displayEmpty
          backend
          id="recipient"
          value={currentMail?.to_id || currentMail?.to?.id || ''}
          setValue={(v) => {
            setCurrentMail({
              ...currentMail,
              to_id: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
            });
          }}
        >
          <MenuItem value="">------</MenuItem>
          {Object.keys(coaches || {}).sort().map(practice_name => [
            <ListSubheader sx={{color: 'lightgrey'}}>{practice_name}</ListSubheader>,
            ...(coaches || {})[practice_name].map(coach => <MenuItem value={coach.id}>{coach.name}</MenuItem>)
          ])}
        </Select>
      </Grid>}
      {!!replyingTo && <Grid item xs={12}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.recipient", "Ontvanger")}</Typography>
        <Typography>{replyingTo.from?.full_name}</Typography>
      </Grid>}

      <Grid item xs={12}>
        <TextInput
          id="subject"
          caption={t("shared.nutri_mail.subject", "Onderwerp")}
          backend
          value={currentMail?.subject || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              subject: e
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Editor
          advanced
          height={300}
          label={t("shared.nutri_mail.body", "Inhoud")}
          value={currentMail?.body || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              body: e
            });
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{marginTop: 2}}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.attachments", "Bijlagen")}</Typography>
        <FileDrop
          onDrop={acceptedFiles => {setItems(acceptedFiles);}}
          multiple={true}
          maxSize={10 * 1000 * 1000}
          items={items}
          dropText="Click/Drop"
          dropHint="All.: all files, < 10mb"
        />
        {savedItems.length > 0 && <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.attachments", "Bijlagen")}</Typography>}
        {savedItems.map(item => <Tooltip title={<Box sx={{maxWidth: 300, maxHeight: 300}}><img src={item.get_item} style={{maxWidth: 280, maxHeight: 280, width: 'auto', height: 'auto'}} /></Box>}><Chip label={item.item_file_name} variant="outlined" color="primary" sx={{marginRight: 0.5}} onDelete={() => {setSavedItems(savedItems.filter(fff => fff.id !== item.id));}} /></Tooltip>)}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{marginBottom: 1}} />
        <Switcher
          id="send_recipient_email"
          caption={t("shared.nutri_mail.send_recipient_email", "Stuur een herinneringsemail naar de ontvanger")}
          value={!!currentMail?.send_email}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              send_email: c
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
        {mutationCustomAction.isPending && <CircularProgress sx={{marginTop: 1}} />}
        {!mutationCustomAction.isPending && <Button
          contained
          id="send"
          sx={{
            marginTop: 1
          }}
          onClick={(e) => {
            saveMail(true, true);
          }}
          label={t("shared.nutri_mail.send", "Verzenden")}
        />}
        {!mutationCustomAction.isPending && <Button
          contained
          disabled={!currentMail.to}
          id="draft"
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          onClick={(e) => {
            saveMail(true);
          }}
          label={t("shared.nutri_mail.save_draft", "Opslaan als draft")}
        />}
        {!mutationCustomAction.isPending && !currentMail?.sent && !!currentMail?.id && <Button
          contained
          id="delete"
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          secondary
          onClick={(e) => {
            mutationDelete.mutate({formData: {
              object: 'nutri_mail',
              id: currentMail.id,
              ob: {
                id: currentMail.id,
              }
            }});
          }}
          label={t("shared.nutri_mail.delete_draft", "Draft verwijderen")}
        />}
        {!mutationCustomAction.isPending && <Button
          label={t("shared.buttons.cancel")}
          id='cancel'
          text
          // contained
          onClick={(v) => {
            navigate("/inbox");
          }}
          sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
        />}
      </Grid>

    </Grid>
  </Page>;
}

