import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {
  Avatar,
  Box,
  Button as Btn,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  ListSubheader,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Tooltip
} from '@mui/material';

import {
  Button,
  Editor,
  FileDrop,
  IconButton,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from '../../../components/v2/dialogs/Base';

import { CoachSkill } from '../../../models/CoachSkill';
import { NutriMail } from '../../../models/NutriMail';
import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {}

export const CoachEditNutriMail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  const { state } = useLocation();

  // !!state && !!state.mail => draft
  // state.reply => reply

  const [replyingTo, setReplyingTo] = useState<NutriMail>();
  const [currentMail, setCurrentMail] = useState<NutriMail>({
    //@ts-ignore
    body: !!state && !!state.reply ? `<p><br/><p>---<br/><b>Van</b>: ${state.reply.from.full_name}<br/><b>Aan</b>: ${(state.reply.nutri_mail_recipients || []).map(mm => mm.recipient?.full_name || '').filter(fff => !!fff).join("; ")}<br/><b>Tijd</b>: ${DateTime.fromISO(state.reply.created_at).toFormat("DDD t")}<br/></p>${state.reply.body}` : `<p><br/></p>${login?.settings && login?.settings.nutri_mail && login?.settings.nutri_mail.signature ? login?.settings.nutri_mail.signature : ''}`,
    send_email: true,
    to_ids: !!state?.activeClient?.id ? [state.activeClient.id] : []
  });
  const [items, setItems] = useState<File[]>([]);
  const [savedItems, setSavedItems] = useState<{
    id: number;
    item_file_name: string;
    get_item: string;
  }[]>([]);
  const [skippedFirstSave, setSkippedFirstSave] = useState<boolean>(false);
  const [clientSearch, setClientSearch] = useState<string>('');
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);

  const [addContact, setAddContact] = useState<boolean>(false);
  const [newContact, setNewContact] = useState<User>({
    email: '',
    auth_token: '',
    coach_skills: []
  });
  const [contactSearch, setContactSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  let count = 0;

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    // refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", 'with_email', currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'last_login_date', 'language_id', 'email', 'first_name', 'last_name'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (login?.practice_id || currentPractice?.id),
              // enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", 'with_email', currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name', 'email', 'get_item_thumb', 'first_name', 'last_name'],
          filter: {
            search: clientSearch,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: restricteds,
    isLoading: restrictedsLoading,
    isError: restrictedsError,
    isSuccess: restrictedsSuccess,
    refetch: restrictedsRefetch,
  } = useQuery({
    queryKey: ["restricteds", 'with_email', currentPractice?.id, contactSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'restricted',
          fields: ['id', 'full_name', 'email', 'first_name', 'last_name', 'coach_skill_ids', 'practice|id/name'],
          filter: {
            search: contactSearch,
            advanced: {
              
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: coachSkills,
    isLoading: coachSkillsLoading,
    isError: coachSkillsError,
    isSuccess: coachSkillsSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coach_skills"],
    queryFn: () =>
      fetch_all<CoachSkill>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach_skill',
          fields: ['id', 'name'],
          order: 'name ASC'
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: practices,
    isLoading: practicesLoading,
    isError: practicesError,
    isSuccess: practicesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["practices", 'coaches_with_email', currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: ['id', 'name', 'coaches|active|not_secretary'],
          sub_fields: {
            'coaches_active_not_secretary': ['id', 'full_name', 'email', 'get_item_thumb', 'first_name', 'last_name']
          },
          filter: {
            search: clientSearch,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1',
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError,
    isSuccess: usersSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["users", 'with_email', currentPractice?.id, (currentMail?.to_ids || []).join(",")],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'email', 'get_item_thumb', 'first_name', 'last_name', 'type', 'practice|id/name'],
          filter: {
            search: '',
            advanced: {
              // enabled: '1',
              nutri_mail_to_ids: (currentMail?.to_ids || [])
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id && (currentMail?.to_ids || []).length > 0
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NutriMail
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();

      if (!!variables.extra?.goback) {
        navigate("/inbox");
      } else {
        setCurrentMail({
          ...currentMail,
          ...data.custom_result.mail
        });
        if (data.custom_result.mail.nutri_mail_attachments) {
          setItems([]);
          setSavedItems(data.custom_result.mail.nutri_mail_attachments);
        }
      }
    },
  });
  const mutationDelete = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NutriMail
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/inbox");
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      addContact?: boolean
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setNewContact({
        email: '',
        auth_token: '',
        coach_skills: []
      });
      if (!!variables.addContact) {
        let id = data.custom_result.id;
        if (!!id && (currentMail.to_ids || []).filter(fff => fff === id).length < 1) {
          setCurrentMail({
            ...currentMail,
            to_ids: [
              ...(currentMail.to_ids || []), id
            ]
          });
        }
      }
      setAddContact(false);
      restrictedsRefetch();
    },
  });








  const saveMail = (goback?: boolean, send?: boolean) => {
    if (!!currentMail) {
      // console.log(currentMail, currentMail.id);
      let data = new FormData();
      data.append('object', 'nutri_mail');
      data.append('class_action', 'custom_api_save_draft');
      if (!!currentMail) data.append('id', currentMail.id?.toString() || '');
      data.append('nutri_mail[to_id]', currentMail.to_id?.toString() || '');
      data.append('nutri_mail[subject]', currentMail.subject || '');
      data.append('nutri_mail[body]', currentMail.body || '');
      if ((currentMail.to_ids || []).length > 0) {
        (currentMail.to_ids || []).forEach((to_id, index) => {
          data.append(`nutri_mail[to_ids][]`, to_id?.toString());
        });
      } else {
        data.append(`nutri_mail[to_ids][]`, '');
      }
      if (items.length > 0) {
        items.forEach((file, index) => {
          data.append(`item${index}`, file);
        });
      }
      if (savedItems.length > 0) {
        savedItems.forEach((file, index) => {
          data.append(`existing_file_ids[]`, file.id.toString());
        });
      }
      data.append('send_email', !!currentMail.send_email ? '1' : '0');
      if (!!send) data.append('send', '1');
      if (!!replyingTo) data.append('reply_id', replyingTo.id?.toString() || '');
      [
        'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
        'nutri_mail_attachments|count', 'nutri_mail_attachments', 'nutri_mail_recipients'
      ].forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      ['id', 'full_name'].forEach((word, index) => {
        data.append(`sub_fields[to][]`, word);
      });
      ['id', 'full_name'].forEach((word, index) => {
        data.append(`sub_fields[from][]`, word);
      });
      ['id', 'get_item', 'item_file_name'].forEach((word, index) => {
        data.append(`sub_fields[nutri_mail_attachments][]`, word);
      });
      ['id', 'recipient', 'read', 'deleted', 'replied'].forEach((word, index) => {
        data.append(`sub_fields[nutri_mail_recipients][]`, word);
      });
      ['id', 'full_name'].forEach((word, index) => {
        data.append(`sub_fields[recipient][]`, word);
      });

      if (!!skippedFirstSave) {
        mutationCustomAction.mutate({formData: data, extra: {goback: !!goback}});
      } else {
        setSkippedFirstSave(true);
      }
    }
  }

  useEffect(() => {
    if (!!state && !!state.reply) {
      setReplyingTo(state.reply);
      setCurrentMail({
        ...currentMail,
        to_ids: [state.reply.from?.id],
        subject: `RE: ${state.reply.subject}`
      });
    } else {
      setReplyingTo(undefined);
    }
  }, [!!state && !!state.reply]);
  useEffect(() => {
    if (!!state && !!state.mail) {
      setFlickerEditor(true);
      setCurrentMail({
        ...state.mail,
        // @ts-ignore
        to_ids: (state.mail.nutri_mail_recipients || []).map(mm => mm.recipient?.id)
      });
      if (state.mail.nutri_mail_attachments) {
        setItems([]);
        setSavedItems(state.mail.nutri_mail_attachments);
      }
      setTimeout(() => {setFlickerEditor(false);}, 200);
    } else {
      
    }
  }, [!!state && !!state.mail]);
  useEffect(() => {
    let to = setTimeout(() => {
      saveMail();
    }, 1000);

    return () => {
      clearTimeout(to);
    }
  }, [currentMail?.body]);

  let results = [];
  results.push(<MenuItem value="">------</MenuItem>);
  results.push(<ListSubheader sx={{color: 'lightgrey'}}>{login?.practice_name}</ListSubheader>);
  (coaches || []).forEach(coach => {
    results.push(<MenuItem value={coach.id}>{coach.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({coach.email})</span></MenuItem>);
  });
  if ((coaches || []).length < 1) {
    results.push(<MenuItem disabled value="no_res">--- Geen resultaten ---</MenuItem>);
  }
  results.push(<ListSubheader sx={{color: 'lightgrey'}}>{t("coach.menu.users")}</ListSubheader>);
  (clients || []).forEach(client => {
    results.push(<MenuItem value={client.id}>{client.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({client.email})</span></MenuItem>);
  });
  if ((clients || []).length < 1) {
    results.push(<MenuItem disabled value="no_res">--- Geen resultaten ---</MenuItem>);
  }
  if (!!clientSearch && clientSearch.length > 2) {
    (practices || []).forEach(practice => {
      if ((practice.coaches_active_not_secretary || []).length > 0) {
        results.push(<ListSubheader sx={{color: 'lightgrey'}}>{practice.name}</ListSubheader>);
        (practice.coaches_active_not_secretary || []).forEach(coach => {
          results.push(<MenuItem value={coach.id}>{coach.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({coach.email})</span></MenuItem>);
        });
      }
    });
  }
  
  return <Page sx={{padding: {xs: 0, md: 3}}}>
    <Grid container spacing={1}>

      {!replyingTo && <Grid item xs={12} md={6}>
        <TextInput
          id="client_search"
          caption={t("coach.models.appointments.client_search", "Zoek client")}
          value={clientSearch}
          backend
          onChange={(e) => {
            setClientSearch(e);
          }}
        />
        <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
      </Grid>}
      {!replyingTo && coachesSuccess && clientsSuccess && <Grid item xs={12} md={6}>
        <Select
          fullWidth
          caption={t("shared.nutri_mail.recipient", "Ontvanger")}
          displayEmpty
          backend
          id="recipient"
          value={''}
          setValue={(v) => {
            console.log(v);
            let id = v.toString() === '' ? undefined : parseInt(v.toString(), 10);
            if (!!id && (currentMail.to_ids || []).filter(fff => fff === id).length < 1) {
              setCurrentMail({
                ...currentMail,
                to_ids: [
                  ...(currentMail.to_ids || []), id
                ]
              });
            }
          }}
        >
          {results}
          {/* <MenuItem value="">------</MenuItem>
          <ListSubheader sx={{color: 'lightgrey'}}>{login?.practice_name}</ListSubheader>
          {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({coach.email})</span></MenuItem>)}
          <ListSubheader sx={{color: 'lightgrey'}}>{t("coach.menu.users")}</ListSubheader>
          {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({client.email})</span></MenuItem>)} */}
          {/* {(practices || []).map(practice => <>
            <ListSubheader sx={{color: 'lightgrey'}}>{practice.name}</ListSubheader>
            {(practice.coaches_active_not_secretary || []).map(coach => <MenuItem value={coach.id}>{coach.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({coach.email})</span></MenuItem>)}
          </>)} */}
        </Select>
      </Grid>}
      {!replyingTo && <Grid item xs={12}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.recipients", "Ontvangers")}</Typography>
        {/* {(coaches || []).filter(fff => (currentMail.to_ids || []).indexOf(fff.id || 0) > -1).map(coach => <Chip
          avatar={<Avatar src={coach.get_item_thumb} />}
          style={{marginRight: 10, marginBottom: 10, marginTop: 6}}
          label={`${coach.first_name} ${coach.last_name}`}
          onDelete={(e) => {
            setCurrentMail({
              ...currentMail,
              to_ids: (currentMail.to_ids || []).filter(fff => fff !== coach.id)
            });
          }}
          variant="outlined"
          deleteIcon={undefined}
          color={"primary"}
        />)} */}
        {/* {(clients || []).filter(fff => (currentMail.to_ids || []).indexOf(fff.id || 0) > -1).map(client => <Chip
          style={{marginRight: 10, marginBottom: 10, marginTop: 6}}
          label={`${client.first_name} ${client.last_name}`}
          onDelete={(e) => {
            setCurrentMail({
              ...currentMail,
              to_ids: (currentMail.to_ids || []).filter(fff => fff !== client.id)
            });
          }}
          variant="outlined"
          deleteIcon={undefined}
          color={"primary"}
        />)} */}
        {false && <IconButton
          onClick={() => {
            setAddContact(true);
          }}
          title={t("shared.nutri_mail.add_external_contact")}
          sx={{
            marginRight: 1
          }}
        >
          <PersonAddIcon />
        </IconButton>}
        
        {(currentMail?.to_ids || []).map(user_id => <>
          {(users || []).filter(fff => fff.id === user_id).map(usr => <Chip
            avatar={usr.type === "User::Coach" ? <Avatar src={usr.get_item_thumb} /> : undefined}
            style={{marginRight: 10, marginBottom: 10, marginTop: 6}}
            label={`${usr.first_name} ${usr.last_name}${usr.type === "User::Restricted" ? ` [${usr.email}]` : ''}${usr.type === "User::User" ? ` [${usr.email}]` : ''}${!!usr.practice_id && usr.practice_id !== (currentPractice?.id || login?.practice_id) ? ` [${usr.practice_name}]` : ''}${usr.type === "User::Restricted" ? ` [${t("restricted.marking.email_contact")}]` : ''}`}
            onDelete={(e) => {
              setCurrentMail({
                ...currentMail,
                to_ids: (currentMail.to_ids || []).filter(fff => fff !== usr.id)
              });
            }}
            variant="outlined"
            deleteIcon={undefined}
            color={"primary"}
          />)}
        </>)}
      </Grid>}
      {!!replyingTo && <Grid item xs={12}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.recipient", "Ontvanger")}</Typography>
        <Typography>{replyingTo.from?.full_name}</Typography>
      </Grid>}

      <Grid item xs={12}>
        <TextInput
          id="subject"
          caption={t("shared.nutri_mail.subject", "Onderwerp")}
          backend
          value={currentMail?.subject || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              subject: e
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {!flickerEditor && <Editor
          advanced
          height={300}
          label={t("shared.nutri_mail.body", "Inhoud")}
          value={currentMail?.body || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              body: e
            });
          }}
        />}
      </Grid>

      <Grid item xs={12} sx={{marginTop: 2}}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.attachments", "Bijlagen")}</Typography>
        <FileDrop
          onDrop={acceptedFiles => {setItems(acceptedFiles);}}
          multiple={true}
          maxSize={10 * 1000 * 1000}
          items={items}
          dropText="Click/Drop"
          dropHint="All.: all files, < 10mb"
        />
        {savedItems.length > 0 && <Typography sx={{fontSize: '0.7rem'}}>{t("shared.nutri_mail.attachments", "Bijlagen")}</Typography>}
        {savedItems.map(item => <Tooltip title={<Box sx={{maxWidth: 300, maxHeight: 300}}><img src={item.get_item} style={{maxWidth: 280, maxHeight: 280, width: 'auto', height: 'auto'}} /></Box>}><Chip label={item.item_file_name} variant="outlined" color="primary" sx={{marginRight: 0.5}} onDelete={() => {setSavedItems(savedItems.filter(fff => fff.id !== item.id));}} /></Tooltip>)}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{marginBottom: 1}} />
        <Switcher
          id="send_recipient_email"
          caption={t("shared.nutri_mail.send_recipient_email", "Stuur een herinneringsemail naar de ontvanger")}
          value={!!currentMail?.send_email}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              send_email: c
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
        {mutationCustomAction.isPending && <CircularProgress sx={{marginTop: 1}} />}
        {!mutationCustomAction.isPending && <Button
          contained
          id="send"
          disabled={(currentMail.to_ids || []).length < 1}
          sx={{
            marginTop: 1
          }}
          onClick={(e) => {
            saveMail(true, true);
          }}
          label={t("shared.nutri_mail.send", "Verzenden")}
        />}
        {!mutationCustomAction.isPending && <Button
          contained
          id="draft"
          disabled={(currentMail.to_ids || []).length < 1}
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          onClick={(e) => {
            saveMail(true);
          }}
          label={t("shared.nutri_mail.save_draft", "Opslaan als draft")}
        />}
        {!mutationCustomAction.isPending && !currentMail?.sent && !!currentMail?.id && <Button
          contained
          id="delete"
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          secondary
          onClick={(e) => {
            mutationDelete.mutate({formData: {
              object: 'nutri_mail',
              id: currentMail.id,
              ob: {
                id: currentMail.id,
              }
            }});
          }}
          label={t("shared.nutri_mail.delete_draft", "Draft verwijderen")}
        />}
        {!mutationCustomAction.isPending && <Button
          label={t("shared.buttons.cancel")}
          id='cancel'
          text
          // contained
          onClick={(v) => {
            navigate("/inbox");
          }}
          sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
        />}
      </Grid>

    </Grid>



    <Base
      name="addContact"
      hidden={!addContact}
      maxWidth="xl"
      content={<Grid container spacing={1}>
        <Grid container spacing={1} xs={12} md={6} sx={{alignContent: 'baseline', paddingRight: 0.5}}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("shared.nutri_mail.create_contact", "Nieuw extern contact aanmaken")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="name"
              starred
              caption={t("shared.nutri_mail.search.name", "Voornaam")}
              value={newContact.first_name || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  first_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="last_name"
              caption={t("shared.nutri_mail.search.last_name", "Achternaam")}
              value={newContact.last_name || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  last_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="email"
              caption={t("shared.nutri_mail.search.email", "Email")}
              value={newContact.email || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  email: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              caption={t("shared.nutri_mail.search.coach_skill", "Discipline")}
              displayEmpty
              backend
              id="coach_skill"
              value={(newContact?.coach_skill_ids || [])[0] || ''}
              setValue={(v) => {
                let id = v.toString() === '' ? undefined : parseInt(v.toString(), 10);
                if (!!id && (currentMail.to_ids || []).filter(fff => fff === id).length < 1) {
                  setNewContact({
                    ...newContact,
                    coach_skill_ids: [id]
                  });
                }
              }}
            >
              {(coachSkills || []).map(skill => <MenuItem value={skill.id}>{skill.name}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="practice_name"
              caption={t("shared.nutri_mail.search.practice_name", "Organisatie/praktijk naam")}
              value={newContact.practice_name || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  practice_name: e
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} xs={12} md={6} sx={{alignContent: 'baseline', paddingLeft: 0.5}}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("shared.nutri_mail.use_contact", "Bestaand extern contact gebruiken")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="filter"
              caption={t("shared.nutri_mail.search.contact_name", "Filter op naam")}
              value={contactSearch || ''}
              onChange={(e) => {
                setContactSearch(e);
              }}
            />
          </Grid>
          {(restricteds || []).length === 0 && <Grid item xs={12}>
            <Box sx={{textAlign: 'center'}}>
              <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
            </Box>
          </Grid>}
          {(restricteds || []).length > 0 && (restricteds || []).map(contact => <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              borderColor: theme.palette.primary.main,
              padding: 1,
              borderRadius: 1
            }}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography>{contact.first_name} {contact.last_name}</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                  <Typography>{contact.practice_name}</Typography>
                </Grid>
                {(contact.coach_skill_ids || []).length > 0 && <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.8rem'}}>{(coachSkills || []).filter(fff => (contact.coach_skill_ids || []).indexOf(fff.id) > -1).map(skill => <Chip label={skill.name} variant="outlined" color="primary" />)}</Typography>
                </Grid>}
                {!!contact.street && <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.8rem'}}>{contact.street}, {contact.zip} {contact.city}</Typography>
                </Grid>}
                <Grid item xs={12} sx={{textAlign: 'right'}}>
                  {/* <Button
                    id="destroy"
                    destroyBtn
                    secondary
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      mutationDeleteContact.mutate({
                        formData: {
                          object: 'ehealth_contact',
                          id: contact.id,
                          ob: {
                            id: contact.id,
                          }
                        }
                      });
                    }}
                    size="small"
                    startIcon={<DeleteForeverIcon />}
                    label={t("shared.nutri_mail.search.delete_contact", "Verwijderen")}
                  /> */}
                  <Button
                    id="edit"
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      setNewContact(contact);
                    }}
                    size="small"
                    startIcon={<EditIcon />}
                    label={t("shared.nutri_mail.search.edit_contact", "Wijzigen")}
                  />
                  <Button
                    contained
                    id="choose"
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      let id = contact.id;
                      if (!!id && (currentMail.to_ids || []).filter(fff => fff === id).length < 1) {
                        setCurrentMail({
                          ...currentMail,
                          to_ids: [
                            ...(currentMail.to_ids || []), id
                          ]
                        });
                      }
                      setAddContact(false);
                    }}
                    size="small"
                    label={t("shared.nutri_mail.search.use_contact", "Ontvanger toevoegen")}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>)}
          {(restricteds || []).length > 0 && <Grid item xs={12}>
            <Stack spacing={2} alignItems="center">
              <Pagination count={Math.ceil(count/10)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
            </Stack>
          </Grid>}
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setAddContact(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn disabled={
          !newContact?.first_name || 
          !newContact?.last_name || 
          !newContact?.email || 
          !newContact?.practice_name
        } onClick={() => {
          mutationSave.mutate({
            formData: {
              object: 'restricted',
              class_action: 'custom_api_create_contact',
              ob: {
                ...newContact
              },
              fields: ['id']
            },
            addContact: false
          });
        }}>{t("coach.general.actions.save")}</Btn>
        <Btn disabled={
          !newContact?.first_name || 
          !newContact?.last_name || 
          !newContact?.email || 
          !newContact?.practice_name
        } onClick={() => {
          mutationSave.mutate({
            formData: {
              object: 'restricted',
              class_action: 'custom_api_create_contact',
              ob: {
                ...newContact
              },
              fields: ['id']
            },
            addContact: true
          });
        }}>{t("coach.general.actions.save_and_add")}</Btn>
      </DialogActions>}
      open={addContact}
      setOpen={setAddContact}
    />
  </Page>;
}

