import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import {
  Box,
  Pagination,
  Stack,
  useMediaQuery
} from '@mui/material';

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';

import {
  IconButton,
  Typography
} from '../styled';

type DataGridProps = {
  action_count?: number;
  actions?: (e:{row:any}) => ReactNode;
  columns: GridColDef[];
  getRowClassName?: (e:{row:any}) => string;
  loader: boolean;
  hidePagination?: boolean;
  objectCount: number;
  objects: Array<any>;
  onSortModelChange?: (e:GridSortModel, f:unknown) => void;
  sortModel?: GridSortModel;
  page: number;
  setPage?: (e:number) => void;
  sx?: object;
  hideCount?: boolean;
};

export const DataGrid: FunctionComponent<DataGridProps> = ({
  objectCount, getRowClassName, objects, columns, loader, onSortModelChange, sortModel, page, setPage, actions, action_count, hidePagination, sx, hideCount
}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));
  const [openMenu, setOpenMenu] = useState<number>();
  const [menuActions, setMenuActions] = useState<ReactNode>();

  let initialState = {pinnedColumns: {left: ['id'], right: ['actions']}};

  if (!!actions) {
    columns.push({
      field: 'actions',
      headerName: '',
      width: (24 + (!!matchesmd ? (action_count || 1) : 1) * 40),
      sortable: false,
      hideable: false,
      renderCell:  (params) => {
        if (!!matchesmd) {
          return actions(params);
        } else {
          return <>
            <IconButton title="Open Menu" color="default" onClick={() => {
              if (openMenu !== params.row.id) setOpenMenu(params.row.id);
              if (openMenu === params.row.id) setOpenMenu(undefined);
              setMenuActions(actions(params));
            }}>
              <MenuIcon />
            </IconButton>
          </>;
        }
      }
    });
  }

  return (<>
    {!hideCount && <Box sx={{marginTop: 2}}>
      <Typography>{objectCount} {t("general.objects_found")}</Typography>
    </Box>}
    <Box sx={{ width: "100%", maxWidth: '100%', overflow: 'hidden' }}>
      <Box sx={{ width: "auto" }}>
        <DataGridPro
          getRowClassName={(params) => {if (!!getRowClassName) {return getRowClassName(params);} else {return '';}}}
          initialState={initialState}
          rows={objects}
          columns={columns.filter(fff => !!matchesmd ? fff : fff.field !== 'get_item')}
          loading={loader}
          autoHeight
          hideFooter
          disableColumnFilter
          editMode="row"
          rowCount={objectCount}
          sortModel={sortModel}
          onSortModelChange={(a, b) => {
            if (!!onSortModelChange) onSortModelChange(a, b);
          }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "6px"
            },
            "& ::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.primary.main
            },
            "& ::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: theme.palette.tertiary?.main
            },
            ".datagrid-row-enabled-false div:not(.MuiDataGrid-cell--pinnedLeft):not(.MuiDataGrid-cell--pinnedRight)": {
              color: 'lightgrey'
            },
            ".datagrid-row-stricken-true div:not(.MuiDataGrid-cell--pinnedLeft):not(.MuiDataGrid-cell--pinnedRight)": {
              textDecoration: 'line-through'
            },
            "div:not(.MuiDataGrid-pinnedColumns) > .datagrid-row-cancelled-true div": {
              color: 'rgba(255, 0, 0, 0.8)',
              backgroundColor:'lightgrey'
            },
            ".MuiDataGrid-pinnedColumns .datagrid-row-enabled-false div": {
              color: 'grey'
            },
            ".MuiDataGrid-pinnedColumnHeaders": {
              backgroundColor: 'white'
            },
            ".MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-cell--pinnedRight": {
              // backgroundColor: "#cfdbc1"
              backgroundColor: theme.palette.primary.light
            },
            ".MuiDataGrid-columnHeaders > div": {
              backgroundColor: 'white !important'
            },
            ...sx
          }}
        />
      </Box>
    </Box>
    {!hidePagination && <Box sx={{marginTop: 2}}>
      <Stack spacing={2} alignItems="center">
        <Pagination
          count={Math.ceil(objectCount / 20)}
          variant="outlined"
          color="primary"
          page={page + 1}
          onChange={(e, p) => {
            if (!!setPage) setPage(p - 1);
          }}
          boundaryCount={2}
          siblingCount={2}
        />
      </Stack>
    </Box>}
    {!matchesmd && !!openMenu && <>
      <Box sx={{
        position: 'absolute',
        bottom: 16,
        left: 8,
        padding: 1,
        borderRadius: 1,
        backgroundColor: 'white',
        border: '1px solid transparent',
        borderColor: theme.palette.primary.main,
        zIndex: 9000,
        width: 'calc(100% - 16px)',
        textAlign: 'center'
      }}>
        {menuActions}
        <IconButton title="Close" color="secondary" onClick={() => {
          setOpenMenu(undefined);
        }} sx={{marginLeft: 3}}>
          <CloseIcon />
        </IconButton>
      </Box>
    </>}
  </>);
}