import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { AttachmentSimple } from '../../../components/v2/AttachmentSimple';

// import { Advice } from '../../../models/Advice';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientIntakeAdviceUploads: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();


  const {
    data: adviceUploads,
    isLoading: adviceUploadsLoading,
    isError: adviceUploadsError,
    isSuccess: adviceUploadsSuccess,
    refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["advice_uploads", login?.id],
    queryFn: () =>
      fetch_all<{id: number, get_item: string, date: string, item_file_name: string, practice_name: string}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'advice_upload',
          fields: ['id', 'get_item', 'item_file_name', 'date', 'practice|id/name'],
          order: 'date DESC, created_at DESC',
          filter: {
            search: '',
            advanced: {
              user_id: login?.id,
              is_hidden: 0
            }
          }
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!login,
  });








  

  return <Grid container spacing={1}>
    {!!adviceUploadsLoading && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    {!!adviceUploadsSuccess && adviceUploads.length < 1 && <Box sx={{textAlign: 'center', width: '100%'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}

    {adviceUploadsSuccess && adviceUploads.length > 0 && <Grid item xs={12} sx={{
      marginTop: 2,
      position: 'relative'
    }}>
      <Paper sx={{
        padding: 2
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {adviceUploads.map(attachment => <AttachmentSimple
              url={attachment.get_item || ''}
              file_name={attachment.item_file_name || ''}
              practice={attachment.practice_name}
              date={attachment.date}
            />)}
          </Grid>
        </Grid>
      </Paper>
    </Grid>}
  </Grid>;
}

