import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PostAddIcon from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';

// import {
//   Avatar,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  IconButton,
  Page
} from '../../../components/v2/styled';
import { CoachShowQuestionnaire } from '../../../components/v2/dialogs/CoachShowQuestionnaire';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Questionnaire } from '../../../models/Questionnaire';
import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

import HelpersPractice from '../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachUserQuestionnaires: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const params = useParams();
  const {t, i18n} = useTranslation(['translations']);

  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  let count = 0;
  let count2 = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [page2, setPage2] = useState<number>(0);
  const [order2, setOrder2] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");
  const [qResultOpen, setQResultOpen] = useState<boolean>(false);
  const [activeQResult, setActiveQResult] = useState<number|undefined>(undefined);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  let is_questionnaire = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'questionnaire'});

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "questionnaire_resuls", objectId, {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          fields: ['id', 'questionnaire', 'questionnaire_id', 'practice', 'completed_at', 'invited_at', 'mail_sent_at', 'is_locked', 'get_item'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              user_id: objectId,
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0 && !!is_questionnaire,
  });
  const {
    data: questionnaires,
    isLoading: questionnairesLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    refetch: questionnairesRefetch,
  } = useQuery({
    queryKey: ["coach", "questionnaires_short", (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all_with_count<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire',
          fields: ['id', 'name'],
          order: order2,
          per_page: 20,
          page: page2,
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      count2 = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && !!is_questionnaire,
  });
  const {
    data: questionnaireResult,
    // isLoading: objectLoading,
    // isError: objectError,
    // isSuccess: objectSuccess,
    refetch: questionnaireResultRefetch,
  } = useQuery({
    queryKey: ["questionnaire_result", (login?.practice_id || currentPractice?.id), activeQResult],
    queryFn: () =>
      fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          id: activeQResult,
          fields: [
            'id', 'name', 'extra_data', 'questionnaire', 'practice', 'comment', 'is_locked', 'is_frontend'
          ],
          sub_fields: {
            'practice': ['id', 'name'],
            'questionnaire': ['id', 'name', 'extra_data', 'content_type']
          },
          practice_id: (login?.practice_id || currentPractice?.id) && !!is_questionnaire
        },
        login
      ),
    // select: (d) => {
    //   return {
    //     ...d,
    //     birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
    //   };
    // },
    enabled: !!(login?.practice_id || currentPractice?.id) && !!activeQResult,
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      pass?: boolean;
      consent?: boolean;
      group?: boolean;
      unlink?: boolean;
      mfa?: boolean;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      questionnairesRefetch();
    },
  });









  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'questionnaire_name', headerName: t("coach.models.questionnaires.name"), width: 200 },
    { field: 'invited_at', headerName: t("coach.models.questionnaire_results.invited_at", "Invited"), width: 250, renderCell: params => <>
      {!!params.row.invited_at && <Dt d={params.row.invited_at} f="DDD t" />}
    </> },
    { field: 'mail_sent_at', headerName: t("coach.models.questionnaire_results.mail_sent_at", "Last mail"), width: 250, renderCell: params => <>
      {!!params.row.mail_sent_at && <Dt d={params.row.mail_sent_at} f="DDD t" />}
    </> },
    { field: 'completed_at', headerName: t("coach.models.questionnaire_results.completed_at", "Completed"), width: 250, renderCell: params => <>
      {!!params.row.completed_at && <Dt d={params.row.completed_at} f="DDD t" />} {!!params.row.is_locked && <LockIcon sx={{marginBottom: -1, color: 'rgba(0, 0, 0, 0.4)'}} />}
    </> },
  ];
  const columns2: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t("coach.models.questionnaires.name"), width: 200 },
  ];

  return <Page
    title={t("coach.menu.questionnaires_long", "Vragenlijsten")}
  >
    <DataGrid
      action_count={3}
      // hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Invite" color="default" onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'questionnaire_result',
                class_action: 'custom_api_create_and_send_invite',
                user_id: objectId,
                questionnaire_id: params.row.questionnaire_id,
                practice_id: currentPractice?.id
              }
            });
          }}>
            <PostAddIcon />
          </IconButton>
          <IconButton title="View" disabled={!params.row.completed_at} color="default" onClick={() => {
            setQResultOpen(true);
            setActiveQResult(params.row.id);
          }}>
            <VisibilityIcon />
          </IconButton>
          {!!params.row.completed_at && (params.row.get_item || '').indexOf("missing.png") < 0 && <IconButton title="Download" color="default" onClick={() => {
            window.open(params.row.get_item, "_blank")
          }}>
            <DownloadIcon />
          </IconButton>}
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        questionnaire_name: u.questionnaire?.name,
        practice_name: u.practice?.name
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <DataGrid
      action_count={1}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Invite" color="default" onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'questionnaire_result',
                class_action: 'custom_api_create_and_send_invite',
                user_id: objectId,
                questionnaire_id: params.row.id,
                practice_id: currentPractice?.id
              }
            });
          }}>
            <PostAddIcon />
          </IconButton>
        </>);
      }}
      objectCount={count2}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(questionnaires || []).filter(fff => (objects || []).map(mm => mm.questionnaire_id).indexOf(fff.id) < 0).map(u => ({
        ...u,
      }))}
      columns={columns2}
      loader={questionnairesLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        // if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder2([a_0_field, a[0].sort].join(' '));
      }}
      page={page2}
      setPage={(p) => {setPage2(p);}}
      sx={{
        marginTop: 2
      }}
    />

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
    {!!questionnaireResult && <CoachShowQuestionnaire
      open={qResultOpen}
      setOpen={setQResultOpen}
      object={questionnaireResult}
      setSavedOpen={(b) => {
        setSavedOpen(b);
        objectsRefetch();
        questionnaireResultRefetch();
      }}
    />}
  </Page>;
}
