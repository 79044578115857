import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom, filterAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PaletteIcon from '@mui/icons-material/Palette';

// import {
//   Avatar,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Fab,
  IconButton,
  Page,
  Typography
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackCopied } from '../../../components/v2/snacks/Copied';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminPractices: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [savedFilter, setSavedFilter] = useAtom(filterAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);
  const [inited2, setInited2] = useState<boolean>(false);

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "practices", {page: page, order: order, search: search, savedFilters: savedFilter.adminPractices}],
    queryFn: () =>
      fetch_all_with_count<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: ['id', 'name', 'url_path', 'enabled', 'extra_data'],
          // order: order,
          per_page: 20,
          // page: page,
          // filter: {
          //   search: search,
          //   advanced: {
              
          //   }
          // },
          ...(savedFilter?.adminPractices || {
            order: order,
            page: page,
            filter: {
              search: search,
              advanced: {
                
              }
            },
          }),
          skip_api_restrictions: 1
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });







  // const mutationDestroy = useMutation({
  //   mutationFn: (pars: {
  //     formData: any;
  //   }) => {
  //     return fetch_one<Practice>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
  //       pars.formData,
  //       login
  //     );
  //   },
  //   onMutate: (pars: {
  //     formData: any;
  //   }) => {
  //     return true;
  //   },
  //   onError: (data, variables, context) => {
  //     return true;
  //   },
  //   onSuccess: (data, variables, context) => {
  //     setDestroyedOpen(true);
  //     objectsRefetch();
  //   },
  // });







  useEffect(() => {
    if (!!inited && !!inited2) {
      if (
        savedFilter?.adminPractices?.page !== page || 
        savedFilter?.adminPractices?.order !== order || 
        savedFilter?.adminPractices?.filter?.search !== search
      ) {
        // console.log('save filters', page, order, search);
        setSavedFilter({
          ...savedFilter || {},
          adminPractices: {
            page: page,
            order: order,
            filter: {
              search: search,
              advanced: {

              }
            }
          }
        });
      }
    }
  }, [page, order, search]);
  useEffect(() => {
    // console.log('inited2bbbb',inited, inited2);
    if (!!inited && !inited2) {
      setInited2(true);
      // console.log('inited2', ((savedFilter || {})[object_type] || {}));
      if (!!savedFilter?.adminPractices?.page) setPage(savedFilter?.adminPractices?.page);
      if (!!savedFilter?.adminPractices?.order) setOrder(savedFilter?.adminPractices?.order);
      if (!!savedFilter?.adminPractices?.filter?.search) setSearch(savedFilter?.adminPractices?.filter?.search);
    }
  }, [!!inited, !!inited2]);
  useEffect(() => {
    // console.log('inited', inited);
    setInited2(false);
    setPage(0);
    setOrder("id DESC");
    setSearch('');
    setInited(true);
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t("admin.models.practices.name"), width: 250 },
    { field: 'url_path', headerName: t("admin.models.practices.url_path"), width: 200, renderCell: params => <>
      <Typography sx={{lineHeight: '52px', fontSize: '0.9rem'}}>{params.row.url_path} <ContentCopyIcon color="primary" sx={{
        cursor: 'pointer',
        marginLeft: 1,
        marginBottom: -1
      }} onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(`https://${params.row.url_path}.nutriportal.eu/`);
        setOpen(true);
      }} /></Typography>
    </> },
  ];

  return <Page title={t("admin.menu.practices")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={2}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" path={`/practice/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton title="Theming" color="default" path={`/practice/${params.row.id}/theming`}>
            <PaletteIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackCopied open={open} setOpen={setOpen} />

    <Fab
      onClick={() => {
        navigate("/practice/new", {});
      }}
    />
  </Page>;
}
