import React, { ElementType, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import PrintIcon from '@mui/icons-material/Print';
import ReplyIcon from '@mui/icons-material/Reply';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import {
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import {
  Dt,
  Typography
} from '../../../components/v2/styled';
import { AttachmentSimple } from '../AttachmentSimple';
import { Base } from './Base';

import { NutriMail as NM } from '../../../models/NutriMail';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  mail: NM;
  refetch?: () => void;
};

export const NutriMail: FunctionComponent<Props> = ({open, setOpen, mail, refetch}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [activeMail, setActiveMail] = useState<NM>();








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NM
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!refetch) refetch();

      if (variables.formData.unread === 1) {
        setOpen(false);
      } else {
        setActiveMail(data.custom_result.mail);
      }
    },
  });









  const switchableButton = (label:string, onClick:() => void, Icon: ElementType) => {
    return <>
      <IconButton sx={{
          display: {
            xs: 'block',
            md: 'none'
          }
        }} title={label} onClick={() => {
          onClick();
        }}>
          <Icon color="primary" />
        </IconButton>
        <Btn
          startIcon={<Icon />}
          onClick={(e) => {
            onClick();
          }}
          color="primary"
          sx={{
            display: {
              xs: 'none',
              md: 'inline-flex'
            }
          }}
        >{label}</Btn>
    </>
  }

  const print_mail = () => {
    var content = document.getElementById("print_contents");
    // @ts-ignore
    var pri = document.getElementById("if_printframe")?.contentWindow;
    pri.document.open();
    pri.document.write(content?.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  useEffect(() => {
    if (!!mail.id) setActiveMail(mail);
  }, [mail]);

  useEffect(() => {
    // if (!!activeMail?.id && !activeMail.read && activeMail.to?.id === login?.id) mutationCustomAction.mutate({
    if (!!activeMail?.id && !(((activeMail.nutri_mail_recipients || []).filter(fff => (fff?.recipient?.id || 0) === login?.id) || [])[0] || {}).read) mutationCustomAction.mutate({
      formData: {
        object: 'nutri_mail',
        class_action: 'custom_api_mark_as',
        id: activeMail.id,
        fields: [
          'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
          'nutri_mail_attachments|count', 'nutri_mail_attachments', 'nutri_mail_recipients'
        ],
        sub_fields: {
          to: ['id', 'full_name'],
          from: ['id', 'full_name'],
          nutri_mail_attachments: ['id', 'get_item', 'item_file_name'],
          nutri_mail_recipients: ['id', 'recipient', 'read', 'deleted', 'replied'],
          recipient: ['id', 'full_name']
        },
        read: 1,
        recipient_id: login?.id
      }
    });
  }, [activeMail]);

  return (<>
    <Base
      name="nutrimail"
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
      maxWidth="xl"
      content={!!activeMail ? <>
        <Grid container sx={{
          marginTop: -1
        }} spacing={1} id="print_contents">
          <style type="text/css" media="print">
          {`img { max-width: 100%; }`}
          </style>
          
          <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: 'bold'}}>{t("shared.nutri_mail.sender", "Verzender")}</span>: {activeMail.from?.full_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: 'bold'}}>{t("shared.nutri_mail.recipient", "Ontvanger")}</span>: {(activeMail.nutri_mail_recipients || []).map(mm => mm.recipient?.full_name || '').filter(fff => !!fff).join("; ")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: 'bold'}}>{t("shared.nutri_mail.time", "Tijdstip")}</span>: <Dt d={activeMail.created_at} f="DDD t" /> ({DateTime.fromISO(activeMail.created_at).toRelative({ locale: i18n.resolvedLanguage || 'nl' })})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{activeMail.subject}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography html sx={{
              "& p": {
                marginTop: 0,
                marginBottom: 0
              }
            }}>{activeMail.body}</Typography>
          </Grid>
          {(activeMail.nutri_mail_attachments_count || 0) > 0 && <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {(activeMail.nutri_mail_attachments || []).map(attachment => <AttachmentSimple
                url={attachment.get_item}
                file_name={attachment.item_file_name}
              />)}
            </Grid>
          </>}
        </Grid>
      </> : <></>}
      actions={<DialogActions>
        {switchableButton(t("shared.nutri_mail.print", "Afprinten"), () => {
          print_mail();
        }, PrintIcon)}
        {!!activeMail && ((((activeMail.nutri_mail_recipients || []).filter(fff => (fff?.recipient?.id || 0) === login?.id) || []).length < 1 && !activeMail.deleted) || (((activeMail.nutri_mail_recipients || []).filter(fff => fff?.recipient?.id || 0 === login?.id) || []).length > 0 && !(((activeMail.nutri_mail_recipients || []).filter(fff => fff?.recipient?.id || 0 === login?.id) || [])[0] || {}).deleted)) && switchableButton(t("shared.nutri_mail.delete", "Verwijderen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'nutri_mail',
              class_action: 'custom_api_mark_as',
              fields: [
                'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
                'nutri_mail_attachments|count', 'nutri_mail_attachments', 'nutri_mail_recipients'
              ],
              sub_fields: {
                to: ['id', 'full_name'],
                from: ['id', 'full_name'],
                nutri_mail_attachments: ['id', 'get_item', 'item_file_name'],
                nutri_mail_recipients: ['id', 'recipient', 'read', 'deleted', 'replied'],
                recipient: ['id', 'full_name']
              },
              id: activeMail.id,
              deleted: 1,
              recipient_id: login?.id
            }
          });
        }, DeleteIcon)}
        {!!activeMail && ((((activeMail.nutri_mail_recipients || []).filter(fff => (fff?.recipient?.id || 0) === login?.id) || []).length < 1 && !!activeMail.deleted) || (((activeMail.nutri_mail_recipients || []).filter(fff => fff?.recipient?.id || 0 === login?.id) || []).length > 0 && !!(((activeMail.nutri_mail_recipients || []).filter(fff => fff?.recipient?.id || 0 === login?.id) || [])[0] || {}).deleted)) && switchableButton(t("shared.nutri_mail.mark_as_undelete", "Herstellen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'nutri_mail',
              class_action: 'custom_api_mark_as',
              fields: [
                'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
                'nutri_mail_attachments|count', 'nutri_mail_attachments', 'nutri_mail_recipients'
              ],
              sub_fields: {
                to: ['id', 'full_name'],
                from: ['id', 'full_name'],
                nutri_mail_attachments: ['id', 'get_item', 'item_file_name'],
                nutri_mail_recipients: ['id', 'recipient', 'read', 'deleted', 'replied'],
                recipient: ['id', 'full_name']
              },
              id: activeMail.id,
              undeleted: 1,
              recipient_id: login?.id
            }
          });
        }, RestoreFromTrashIcon)}
        {!!activeMail && (((activeMail.nutri_mail_recipients || []).filter(fff => (fff?.recipient?.id || 0) === login?.id) || []).length > 0 && !!(((activeMail.nutri_mail_recipients || []).filter(fff => fff?.recipient?.id || 0 === login?.id) || [])[0] || {}).read) && switchableButton(t("shared.nutri_mail.mark_as_unread", "Markeren als ongelezen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'nutri_mail',
              class_action: 'custom_api_mark_as',
              fields: [
                'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
                'nutri_mail_attachments|count', 'nutri_mail_attachments', 'nutri_mail_recipients'
              ],
              sub_fields: {
                to: ['id', 'full_name'],
                from: ['id', 'full_name'],
                nutri_mail_attachments: ['id', 'get_item', 'item_file_name'],
                nutri_mail_recipients: ['id', 'recipient', 'read', 'deleted', 'replied'],
                recipient: ['id', 'full_name']
              },
              id: activeMail.id,
              unread: 1,
              recipient_id: login?.id
            }
          });
        }, MarkEmailUnreadIcon)}
        {switchableButton(t("shared.nutri_mail.reply", "Beantwoorden"), () => {
          navigate("/inbox/new", {state: {reply: activeMail}});
        }, ReplyIcon)}
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("shared.nutri_mail.close", "Sluiten")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    <iframe id="if_printframe" title="Print NutriMail" style={{height: 0, width: 0, position: 'absolute', left: -1000, top: -1000}} />
  </>);
}
