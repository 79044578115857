import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Avatar,
  Box,
  Divider
} from '@mui/material';

import {
  Dt,
  IconButton,
  Typography
} from './styled';

type Props = {
  url: string;
  file_name: string;
  practice?: string;
  date?: string;
  is_hidden?: boolean;
  onDelete?: () => void;
};

export const AttachmentSimple: FunctionComponent<Props> = ({onDelete, url, file_name, practice, date, is_hidden}) => {
  const theme = useTheme();

  const onClick = (e:any, isDelete: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDelete) window.open(url);
    if (!!isDelete && !!onDelete) onDelete();
  }

  return (<Box sx={{
    cursor: 'pointer',
    padding: 1,
    border: '1px solid transprent',
    borderColor: theme.palette.primary.main,
    display: 'inline-block',
    borderRadius: 5,
    marginRight: 1,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      "button": {
        display: 'inline'
      }
    },
    width: '250px'
  }} onClick={(e) => {
    if (!onDelete) {
      onClick(e, false);
    }
  }}>
    {!!onDelete && <IconButton sx={{display: 'none', position: 'absolute', top: 0, left: 40, zIndex: 1201}} destroyBtn onClick={(e) => {
      onClick(e, true);
    }}>
      <DeleteForeverIcon color="secondary" />
    </IconButton>}
    {!!onDelete && <IconButton sx={{display: 'none', position: 'absolute', top: 0, left: 0, zIndex: 1201}} onClick={(e) => {
      onClick(e, false);
    }}>
      <VisibilityIcon color="primary" />
    </IconButton>}
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center'
    }}>
      <Avatar sx={{
        maxWidth: 100,
        maxHeight: 100,
        height: 'auto',
        width: 'auto',
        display: 'inline-block'
      }} variant="square" src={url}>
        {file_name.indexOf(".pdf") > -1 && <PictureAsPdfIcon sx={{fontSize: '4rem'}} />}
        {file_name.indexOf(".zip") > -1 && <FolderZipIcon sx={{fontSize: '4rem'}} />}
      </Avatar>
    </Box>
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center',
      '& span': {
        maxWidth: '100%',
        display: 'block'
      },
      position: 'relative'
    }}>
      {!!is_hidden && <VisibilityOffIcon color="primary" sx={{
        position: 'absolute',
        top: -16,
        right: 0,
        opacity: 0.7
      }} />}
      {!!practice && <Typography sx={{fontWeight: 'bold'}}>{practice}</Typography>}
      {!!date && <Dt d={date} f="DDD" />}
      {(!!practice || !!date) && <Divider />}
      <Typography sx={{wordBreak: 'break-all'}}>{file_name}</Typography>
    </Box>
  </Box>);
}
