import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  CardContent,
  CardMedia,
  Chip,
  Divider
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';

import { Location } from '../../../models/Location';

type Props = {
  location: Location;
  sx?: any;
};

export const CardLocation: FunctionComponent<Props> = ({location, sx}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<CardBase sx={{...sx}}>
    <CardMedia
      sx={{
        height: 100,
        backgroundPositionY: '40%',
        backgroundColor: (!!(location.settings || {}).is_background_colour ? (!!(location.settings || {}).background_colour ? (location.settings || {}).background_colour : 'rgba(0, 0, 0, 0.2)') : 'transparent')
      }}
      image={!(location.settings || {}).is_background_colour ? location.get_bg_medium : undefined}
      title={`${location.name}`}
    />
    <CardContent style={{marginTop: '-100px', marginBottom: 'auto'}}>
      <Avatar sx={{width: 100, height: 100}} src={location.get_medium} />

      <Typography sx={{color: theme.palette.tertiary?.main, marginTop: 1}}>{location.name}</Typography>

      {/* {(location.practice_types || []).length > 0 && <>
        <Divider sx={{marginTop: 1, marginBottom: 1}} />

        <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem'}}>{t("client.models.coaches.skills", "Skills")}</Typography>
        {(location.practice_types || []).map(type => <Chip
          color="primary"
          size="small"
          variant="outlined"
          label={type.name}
          sx={{
            marginRight: 0.5,
            marginBottom: 0.5,
            marginTop: 0.5
          }}
        />)}
      </>} */}

      <Divider sx={{marginTop: 1, marginBottom: 1}} />

      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem'}}>{t("client.models.locations.address", "Adres")}</Typography>
      {!!location.is_online && !location.is_physical && <Typography>{t("client.appointment.online_location", "Online consultatie")}</Typography>}
      {!!location.is_online && !!location.is_physical && <Typography>{t("client.appointment.online_location_with_phys", "Online consultatie beschikbaar")}</Typography>}
      {!!location.is_physical && <Typography>{location.street} {location.number}<br />{location.zip} {location.city}</Typography>}
    </CardContent>
  </CardBase>);
}