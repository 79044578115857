import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {
  Avatar,
  Box,
  Button as Btn,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
  Pagination,
  Stack,
  Tooltip
} from '@mui/material';

import {
  Button,
  Editor,
  FileDrop,
  IconButton,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from '../../../components/v2/dialogs/Base';

import { EhealthContact } from '../../../models/EhealthContact';
import { EhealthMail } from '../../../models/EhealthMail';
import { User } from '../../../models/User';

import HelpersEhealth from '../../../actions/helpers/ehealth';
import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachEditMail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  const { state } = useLocation();

  // !!state && !!state.mail => draft
  // state.reply => reply

  const [replyingTo, setReplyingTo] = useState<EhealthMail>();
  const [currentMail, setCurrentMail] = useState<EhealthMail>({
    // body: !!state && !!state.reply ? `<p><br/><p>---<br/><b>Van</b>: ${state.reply.from.full_name}<br/><b>Aan</b>: ${state.reply.to.full_name}<br/><b>Tijd</b>: ${DateTime.fromISO(state.reply.created_at).toFormat("DDD t")}<br/></p>${state.reply.body}` : `<p><br/></p>${login?.settings && login?.settings.ehealth_mail && login?.settings.ehealth_mail.signature ? login?.settings.ehealth_mail.signature : ''}`
    // extra_data: {
    //   draft_info: {
    //     attributes: {
    //       to_id: state?.client_id || ''
    //     }
    //   }
    // }
  });
  const [items, setItems] = useState<File[]>([]);
  const [savedItems, setSavedItems] = useState<{
    id: number;
    item_file_name: string;
    get_item: string;
  }[]>([]);
  const [skippedFirstSave, setSkippedFirstSave] = useState<boolean>(false);
  const [clientSearch, setClientSearch] = useState<string>('');

  const [addContact, setAddContact] = useState<boolean>(false);
  const [newContact, setNewContact] = useState<EhealthContact>({
    entity_type: 'nihii',
    is_user: true
  });
  const [contactSearch, setContactSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  let count = 0;

  const is_ehealth_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'ehealth'});

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    // refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", 'with_email_rrn', currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'last_login_date', 'language_id', 'email', 'national_number'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (login?.practice_id || currentPractice?.id),
              // enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: eContactsAll,
    // isLoading: eContactsLoading,
    // isError: eContactsError,
    // isSuccess: eContactsSuccess,
    refetch: eContactsAllRefetch,
  } = useQuery({
    queryKey: ["eContactsAll", login?.id],
    queryFn: () =>
      fetch_all_with_count<EhealthContact>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'ehealth_contact',
          fields: ['id', 'first_name', 'last_name', 'quality', 'entity_identifier', 'entity_type', 'street', 'zip', 'city', 'is_user'],
          order: "first_name ASC, last_name ASC",
          per_page: 1000,
          filter: {
            search: '',
            advanced: {
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id
  });
  const {
    data: eContacts,
    isLoading: eContactsLoading,
    isError: eContactsError,
    isSuccess: eContactsSuccess,
    refetch: eContactsRefetch,
  } = useQuery({
    queryKey: ["eContacts", login?.id, {search: contactSearch, page: page}],
    queryFn: () =>
      fetch_all_with_count<EhealthContact>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'ehealth_contact',
          fields: ['id', 'first_name', 'last_name', 'quality', 'entity_identifier', 'entity_type', 'street', 'zip', 'city', 'is_user'],
          order: "first_name ASC, last_name ASC",
          page: page,
          per_page: 10,
          filter: {
            search: contactSearch,
            advanced: {
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: EhealthMail
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();

      if (!!variables.extra?.goback) {
        navigate("/ehealth_inbox");
      } else {
        setCurrentMail({
          ...currentMail,
          ...data.custom_result
        });
        if (data.custom_result.ehealth_mail_attachments) {
          setItems([]);
          setSavedItems(data.custom_result.ehealth_mail_attachments);
        }
      }
    },
  });
  const mutationDelete = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: EhealthMail
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/ehealth_inbox");
    },
  });
  const mutationDeleteContact = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<EhealthContact>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      eContactsRefetch();
      eContactsAllRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      addContact?: boolean
    }) => {
      return fetch_one<EhealthContact>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setNewContact({});
      if (!!variables.addContact) {
        setCurrentMail({
          ...currentMail,
          extra_data: {
            ...(currentMail.extra_data || {}),
            draft_info: {
              ...(currentMail.extra_data?.draft_info || {}),
              attributes: {
                ...(currentMail.extra_data?.draft_info?.attributes || {}),
                to_ids: [
                  ...(currentMail.extra_data?.draft_info?.attributes.to_ids || []),
                  (data.id || 0)
                ]
              }
            }
          }
        });
      }
      setAddContact(false);
      eContactsRefetch();
      eContactsAllRefetch();
    },
  });








  const saveMail = (goback?: boolean, send?: boolean) => {
    if (!!currentMail) {
      let data = new FormData();
      data.append('object', 'ehealth_mail');
      data.append('class_action', 'custom_api_save_draft');
      if (!!currentMail?.id) data.append('existing_id', currentMail.id?.toString() || '');
      data.append('ehealth_mail[extra_data][draft_info][attributes][subject]', currentMail.extra_data?.draft_info?.attributes?.subject || '');
      data.append('ehealth_mail[extra_data][draft_info][attributes][body]', currentMail.extra_data?.draft_info?.attributes?.body || '');
      data.append('ehealth_mail[extra_data][draft_info][attributes][comment]', currentMail.extra_data?.draft_info?.attributes?.comment || '');
      (currentMail.extra_data?.draft_info?.attributes.to_ids || []).forEach((id, index) => {
        data.append(`ehealth_mail[extra_data][draft_info][attributes][to_ids][]`, id.toString());
      });
      data.append('ehealth_mail[extra_data][draft_info][attributes][to_id]', currentMail.extra_data?.draft_info?.attributes?.to_id?.toString() || '');
      data.append('ehealth_mail[extra_data][draft_info][attributes][to_rrn]', currentMail.extra_data?.draft_info?.attributes?.to_rrn || '');
      data.append('ehealth_mail[extra_data][draft_info][attributes][encrypted]', !!currentMail.extra_data?.draft_info?.attributes?.encrypted ? '1' : '0');
      data.append('ehealth_mail[extra_data][draft_info][attributes][read_notice]', !!currentMail.extra_data?.draft_info?.attributes?.read_notice ? '1' : '0');
      data.append('ehealth_mail[extra_data][draft_info][attributes][view_notice]', !!currentMail.extra_data?.draft_info?.attributes?.view_notice ? '1' : '0');
      data.append('ehealth_mail[extra_data][draft_info][attributes][ignore_ooo]', !!currentMail.extra_data?.draft_info?.attributes?.ignore_ooo ? '1' : '0');

      if (items.length > 0) {
        items.forEach((file, index) => {
          data.append(`item${index}`, file);
        });
      }
      if (savedItems.length > 0) {
        savedItems.forEach((file, index) => {
          data.append(`existing_file_ids[]`, file.id.toString());
        });
      }
      if (!!send) data.append('send', '1');
      if (!!replyingTo) data.append('reply_id', replyingTo.id?.toString() || '');
      [
        'id', 'sender', 'recipients', 'content_data', 'extra_data', 'created_at', 'unread', 'is_draft', 'identifier',
        'ehealth_mail_attachments|count', 'ehealth_mail_attachments'
      ].forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      ['id', 'get_item', 'item_file_name'].forEach((word, index) => {
        data.append(`sub_fields[ehealth_mail_attachments][]`, word);
      });

      if (!!skippedFirstSave) {
        mutationCustomAction.mutate({formData: data, extra: {goback: !!goback}});
      } else {
        setSkippedFirstSave(true);
      }
    }
  }

  useEffect(() => {
    if (!!state?.reply) {
      console.log('reply', state?.reply);
      let sender_name = ((!!state.reply.sender && !!state.reply.sender.actor) ? [state.reply.sender.actor.organizationName, state.reply.sender.actor.firstName, ' ', state.reply.sender.actor.lastName].join("") : '');
      // @ts-ignore
      let recipients = (state.reply.recipients || []).map(x => [!!x.person ? x.person.firstName : x.identifiers.entity, !!x.person ? x.person.lastName : `(${HelpersEhealth.get_quality(x.identifiers.quality)})`].join(" ")).join("; ");
      setCurrentMail({
        ...currentMail,
        extra_data: {
          ...(currentMail.extra_data || {}),
          draft_info: {
            ...(currentMail.extra_data?.draft_info || {}),
            attributes: {
              ...(currentMail.extra_data?.draft_info?.attributes || {}),
              to_ids: [
                state.reply.sender.contact_id
              ],
              to_id: (!!state?.user_id ? state.user_id : undefined),
              subject: `RE: ${state.reply.content_data.title}`,
              body: `<p></p><p>---<br/><b>Van</b>: ${sender_name}<br/><b>Aan</b>: ${recipients}<br/><b>Tijd</b>: ${DateTime.fromISO(state.reply.created_at).toFormat("LL LT")}<br/></p>${state.reply.content_data.payload}`
            }
          }
        }
      });
      setReplyingTo(state.reply);
    } else {
      setCurrentMail({
        ...currentMail,
        extra_data: {
          ...(currentMail.extra_data || {}),
          draft_info: {
            ...(currentMail.extra_data?.draft_info || {}),
            attributes: {
              ...(currentMail.extra_data?.draft_info?.attributes || {}),
              to_id: (!!state?.user_id ? state.user_id : undefined),
              body: login?.settings?.ehealth_mail?.signature
            }
          }
        }
      });
      setReplyingTo(undefined);
    }
  }, [state, login]);
  // useEffect(() => {
  //   if (!!state && !!state?.reply) {
  //     setReplyingTo(state.reply);
  //     setCurrentMail({
  //       ...currentMail,
  //       // to_id: state.reply.from?.id,
  //       // subject: `RE: ${state.reply.subject}`
  //     });
  //   } else {
  //     setReplyingTo(undefined);
  //   }
  // }, [!!state && !!state.reply]);
  useEffect(() => {
    if (!!state && !!state.mail) {
      setCurrentMail({
        ...state.mail
      });
      if (state.mail.ehealth_mail_attachments) {
        setItems([]);
        setSavedItems(state.mail.ehealth_mail_attachments);
      }
    } else {
      
    }
    if (!!state && !!state.client_id && (clients || []).length > 0) {
      setCurrentMail({
        ...(currentMail || {}),
        extra_data: {
          ...(currentMail.extra_data || {}),
          draft_info: {
            ...(currentMail.extra_data?.draft_info || {}),
            attributes: {
              ...(currentMail.extra_data?.draft_info?.attributes || {}),
              to_id: parseInt(state.client_id.toString(), 10),
              to_rrn: ((clients || []).filter(fff => fff.id === parseInt(state.client_id.toString(), 10))[0] || {}).national_number
            }
          }
        }
      });
    }
  }, [!!state && !!state.mail, clients]);
  useEffect(() => {
    let to = setTimeout(() => {
      saveMail();
    }, 1000);

    return () => {
      clearTimeout(to);
    }
  }, [currentMail?.extra_data?.draft_info?.attributes?.subject, currentMail?.extra_data?.draft_info?.attributes?.body, currentMail?.extra_data?.draft_info?.attributes?.comment]);
  
  return <Page
  sx={{padding: {xs: 0, md: 3}}}
  premiumGatekeeping={(login?.type === "User::Coach" && !!is_ehealth_mail)}
  premiumMode='ehealth'
  >
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.ehealth_mail.recipient", "Ontvanger")}</Typography>
        <Box sx={{
          position: 'relative',
          width: '100%',
          minHeight: '50px',
          paddingLeft: '50px'
        }}>
          <Box sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '50px',
            height: '50px'
          }}>
            <IconButton
              onClick={() => {
                setAddContact(true);
              }}
            >
              <PersonAddIcon />
            </IconButton>
          </Box>
          <Typography>{!!currentMail && (eContactsAll || []).length > 0 && (currentMail.extra_data?.draft_info?.attributes?.to_ids || []).map(to_id => {
            let rec:EhealthContact = {};
            (eContactsAll || []).map(cont => {
              if (to_id === cont.id) rec = cont;
            });
            return (<>
              <Chip
                avatar={rec.entity_type && rec.entity_type.toLowerCase() === "ssin" ? <Avatar><NoEncryptionIcon style={{fontSize: '0.9rem'}} /></Avatar> : <></>}
                style={{marginRight: 10, marginBottom: 10, marginTop: 6}}
                label={`${rec.first_name} ${rec.last_name}`}
                onClick={() => {
                  setNewContact(rec);
                  setAddContact(true);
                }}
                onDelete={(e) => {
                  setCurrentMail({
                    ...currentMail,
                    extra_data: {
                      ...(currentMail.extra_data || {}),
                      draft_info: {
                        ...(currentMail.extra_data?.draft_info || {}),
                        attributes: {
                          ...(currentMail.extra_data?.draft_info?.attributes || {}),
                          to_ids: (currentMail.extra_data?.draft_info?.attributes.to_ids || []).filter(fff => fff !== rec.id)
                        }
                      }
                    }
                  });
                }}
                variant="outlined"
                deleteIcon={undefined}
                color={"primary"}
              />
            </>);
          })}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextInput
          id="client_search"
          caption={t("coach.models.appointments.client_search", "Zoek client")}
          value={clientSearch}
          backend
          onChange={(e) => {
            setClientSearch(e);
          }}
        />
        <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Select
          fullWidth
          caption={t("shared.ehealth_mail.linked_client", "Gelinkte client")}
          displayEmpty
          backend
          id="recipient"
          value={currentMail.extra_data?.draft_info?.attributes?.to_id || ''}
          setValue={(v) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    to_id: v.toString() === '' ? undefined : parseInt(v.toString(), 10),
                    to_rrn: ((clients || []).filter(fff => fff.id === parseInt(v.toString(), 10))[0] || {}).national_number
                  }
                }
              }
            });
          }}
        >
          <MenuItem value="">------</MenuItem>
          {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name} <span style={{color: 'rgba(0, 0, 0, 0.5)', marginLeft: 8, fontSize: '0.9rem'}}>({client.email})</span></MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <TextInput
          id="to_rrn"
          caption={t("shared.ehealth_mail.client_rrn", "Rijksregisternummer")}
          value={currentMail.extra_data?.draft_info?.attributes?.to_rrn || ''}
          backend
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    to_rrn: e
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="subject"
          caption={t("shared.ehealth_mail.subject", "Onderwerp")}
          value={currentMail.extra_data?.draft_info?.attributes?.subject || ''}
          backend
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    subject: e
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{textDecoration: 'underline'}}>{t("shared.ehealth_mail.body", "Inhoud")}:</Typography>
        <Editor
          advanced
          label=""
          value={currentMail.extra_data?.draft_info?.attributes?.body || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    body: e
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{textDecoration: 'underline'}}>{t("shared.ehealth_mail.comment", "Commentaar")}:</Typography>
        <Editor
          advanced
          label=""
          value={currentMail.extra_data?.draft_info?.attributes?.comment || ''}
          onChange={(e) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    comment: e
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{marginTop: 2}}>
        <Typography sx={{fontSize: '0.7rem'}}>{t("shared.ehealth_mail.attachments", "Bijlagen")}</Typography>
        <FileDrop
          onDrop={acceptedFiles => {setItems(acceptedFiles);}}
          multiple={true}
          maxSize={10 * 1000 * 1000}
          items={items}
          dropText="Click/Drop"
          dropHint="All.: all files, < 10mb"
        />
        {savedItems.length > 0 && <Typography sx={{fontSize: '0.7rem'}}>{t("shared.ehealth_mail.attachments", "Bijlagen")}</Typography>}
        {savedItems.map(item => <Tooltip title={<Box sx={{maxWidth: 300, maxHeight: 300}}><img src={item.get_item} style={{maxWidth: 280, maxHeight: 280, width: 'auto', height: 'auto'}} /></Box>}><Chip label={item.item_file_name} variant="outlined" color="primary" sx={{marginRight: 0.5}} onDelete={() => {setSavedItems(savedItems.filter(fff => fff.id !== item.id));}} /></Tooltip>)}
      </Grid>

      <Grid item xs={3}>
        <Switcher
          id="read_notice"
          caption={t("shared.ehealth_mail.read_notice", "Leesbevestiging")}
          value={!!currentMail.extra_data?.draft_info?.attributes?.read_notice}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    read_notice: !!c
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Switcher
          id="view_notice"
          caption={t("shared.ehealth_mail.view_notice", "Inlaadbevestiging")}
          value={!!currentMail.extra_data?.draft_info?.attributes?.view_notice}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    view_notice: !!c
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Switcher
          id="ignore_ooo"
          caption={t("shared.ehealth_mail.ignore_ooo", "Out of Office negeren")}
          value={!!currentMail.extra_data?.draft_info?.attributes?.ignore_ooo}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    ignore_ooo: !!c
                  }
                }
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Switcher
          id="encrypted"
          caption={t("shared.ehealth_mail.encrypted", "Geencrypteerd")}
          disabled={(currentMail.extra_data?.draft_info?.attributes?.to_ids || []).map(mm => {
            let rec:EhealthContact = {};
            (eContactsAll || []).map(cont => {
              if (mm === cont.id) rec = cont;
            });
            return rec;
          }).filter(r => !!r.entity_type && r.entity_type.toLowerCase() === "ssin").length > 0}
          value={!!currentMail.extra_data?.draft_info?.attributes?.encrypted}
          onChange={(c) => {
            setCurrentMail({
              ...currentMail,
              extra_data: {
                ...(currentMail.extra_data || {}),
                draft_info: {
                  ...(currentMail.extra_data?.draft_info || {}),
                  attributes: {
                    ...(currentMail.extra_data?.draft_info?.attributes || {}),
                    encrypted: !!c && (currentMail.extra_data?.draft_info?.attributes?.to_ids || []).map(mm => {
                      let rec:EhealthContact = {};
                      (eContactsAll || []).map(cont => {
                        if (mm === cont.id) rec = cont;
                      });
                      return rec;
                    }).filter(r => !!r.entity_type && r.entity_type.toLowerCase() === "ssin").length === 0
                  }
                }
              }
            });
          }}
        />
        {(currentMail.extra_data?.draft_info?.attributes?.to_ids || []).map(mm => {
          let rec:EhealthContact = {};
          (eContactsAll || []).map(cont => {
            if (mm === cont.id) rec = cont;
          });
          return rec;
        }).filter(r => !!r.entity_type && r.entity_type.toLowerCase() === "ssin").length > 0 ? <Typography sx={{color: theme.palette.secondary.main, fontStyle: 'italic', fontSize: '0.8rem'}}>{t("shared.ehealth_mail.error_encryption_not_possible")}</Typography> : <></>}
      </Grid>

      <Grid item xs={12}>
        <Divider />
        {mutationCustomAction.isPending && <CircularProgress sx={{marginTop: 1}} />}
        {!mutationCustomAction.isPending && <Button
          contained
          disabled={(currentMail.extra_data?.draft_info?.attributes?.to_ids || []).length < 1 || (currentMail.extra_data?.draft_info?.attributes?.subject || '').length < 1 || login?.ehealth?.status.key !== "connected"}
          id="send"
          sx={{
            marginTop: 1
          }}
          onClick={(e) => {
            saveMail(true, true);
          }}
          label={t("shared.ehealth_mail.send", "Verzenden")}
        />}
        {!mutationCustomAction.isPending && <Button
          contained
          id="draft"
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          onClick={(e) => {
            saveMail(true);
          }}
          label={t("shared.ehealth_mail.save_draft", "Opslaan als draft")}
        />}
        {!mutationCustomAction.isPending && !currentMail?.identifier && !!currentMail?.id && <Button
          contained
          id="delete"
          sx={{
            marginTop: 1,
            marginLeft: 1
          }}
          secondary
          onClick={(e) => {
            mutationDelete.mutate({formData: {
              object: 'ehealth_mail',
              id: currentMail.id,
              ob: {
                id: currentMail.id,
              }
            }});
          }}
          label={t("shared.ehealth_mail.delete_draft", "Draft verwijderen")}
        />}
        {!mutationCustomAction.isPending && <Button
          label={t("shared.buttons.cancel")}
          id='cancel'
          text
          // contained
          onClick={(v) => {
            navigate("/ehealth_inbox");
          }}
          sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
        />}
      </Grid>

    </Grid>


    <Base
      name="addContact"
      hidden={!addContact}
      maxWidth="xl"
      content={<Grid container spacing={1}>
        <Grid container spacing={1} xs={12} md={6} sx={{alignContent: 'baseline', paddingRight: 0.5}}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("shared.ehealth_mail.create_contact", "Nieuw contact aanmaken")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="name"
              starred
              caption={t("shared.ehealth_mail.search.name", "Voornaam of organisatienaam")}
              value={newContact.first_name || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  first_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="last_name"
              caption={t("shared.ehealth_mail.search.last_name", "Achternaam")}
              value={newContact.last_name || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  last_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="street"
              caption={t("shared.ehealth_mail.search.street", "Straat")}
              value={newContact.street || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  street: e
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              id="zip"
              caption={t("shared.ehealth_mail.search.zip", "Postcode")}
              value={newContact.zip || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  zip: e
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              id="city"
              caption={t("shared.ehealth_mail.search.city", "Gemeente")}
              value={newContact.city || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  city: e
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              caption={t("shared.ehealth_mail.search.entity_type", "Identificatie type")}
              displayEmpty
              starred
              backend
              id="entity_type"
              value={newContact?.entity_type || 'nihii'}
              setValue={(v) => {
                setNewContact({
                  ...newContact,
                  entity_type: v.toString()
                });
              }}
            >
              <MenuItem value="ssin">SSIN</MenuItem>
              <MenuItem value="nihii">NIHII</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="entity_identifier"
              starred
              caption={t("shared.ehealth_mail.search.entity_identifier", "SSIN of RIZIV-nummer")}
              value={newContact.entity_identifier || ''}
              onChange={(e) => {
                setNewContact({
                  ...newContact,
                  entity_identifier: e
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              fullWidth
              caption={t("shared.ehealth_mail.search.entity_type_org", "Persoon of organisatie")}
              displayEmpty
              starred
              backend
              id="is_user"
              value={!!newContact?.is_user ? 'person' : 'org'}
              setValue={(v) => {
                setNewContact({
                  ...newContact,
                  is_user: v.toString() == 'person'
                });
              }}
            >
              <MenuItem value="person">Persoon</MenuItem>
              <MenuItem value="org">Organisatie</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}>
            <Select
              fullWidth
              starred
              caption={t("shared.ehealth_mail.search.quality", "Type persoon/organisatie")}
              displayEmpty
              backend
              id="quality"
              value={newContact?.quality || ''}
              setValue={(v) => {
                setNewContact({
                  ...newContact,
                  quality: v.toString()
                });
              }}
            >
              {(!!newContact.is_user ? [
                {
                  value: "AMBULANCE_RESCUER",
                  label: "Ambulancier"
                }, {
                  value: "DOCTOR",
                  label: "Arts"
                }, {
                  value: "PHARMACIST",
                  label: "Apotheker"
                }, {
                  value: "BIOLOGIST_PHARMACIST",
                  label: "Apotheker biologist"
                }, {
                  value: "PHARMACIST_ASSISTANT",
                  label: "Assistant-apotheker"
                }, {
                  value: "AUDICIEN",
                  label: "Audicien"
                }, {
                  value: "AUDIOLOGIST",
                  label: "Audioloog"
                }, {
                  value: "READAPTATION_BACHELOR",
                  label: "Bachelor in de readaptatiewetenschappen"
                }, {
                  value: "APPLIED_PSYCH_BACHELOR",
                  label: "Bachelor in de toegepaste psychologie"
                }, {
                  value: "FAMILY_SCIENCE_BACHELOR",
                  label: "Bachelor in gezinwetenschappen"
                }, {
                  value: "TRUSS_MAKER",
                  label: "Bandagist"
                }, {
                  value: "CITIZEN",
                  label: "Burger"
                }, {
                  value: "DIETICIAN",
                  label: "Diëtist"
                }, {
                  value: "OCCUPATIONAL_THERAPIST",
                  label: "Ergotherapeut"
                }, {
                  value: "SPECIALIZED_EDUCATOR",
                  label: "Gespecialiseerde opvoeder/begeleider"
                }, {
                  value: "PHYSIOTHERAPIST",
                  label: "Kinesitherapeut"
                }, {
                  value: "CLINICAL_ORTHOPEDIC_PEDAGOGUE",
                  label: "Klinisch orthopedagoog"
                }, {
                  value: "CLINICAL_PSYCHOLOGIST",
                  label: "Klinisch psycholoog"
                }, {
                  value: "LAB_TECHNOLOGIST",
                  label: "Laborant"
                }, {
                  value: "LOGOPEDIST",
                  label: "Logopedist"
                }, {
                  value: "SOCIAL_WORKER",
                  label: "Maatschappelijk werker"
                }, {
                  value: "GERONTOLOGY_MASTER",
                  label: "Master in de gerontologie"
                }, {
                  value: "ORTHOPEDAGOGIST_MASTER",
                  label: "Master in de orthopedagogie"
                }, {
                  value: "PSYCHOMOTOR_THERAPY",
                  label: "Master in de psycho-motoriek"
                }, {
                  value: "DENTAL_HYGIENIST",
                  label: "Mondhygiënist"
                }, {
                  value: "OPTICIEN",
                  label: "Opticien"
                }, {
                  value: "ORTHOPEDIST",
                  label: "Orthopedist"
                }, {
                  value: "ORTHOPTIST",
                  label: "Orthoptist"
                }, {
                  value: "PODOLOGIST",
                  label: "Podoloog"
                }, {
                  value: "PSYCHOLOGIST",
                  label: "Psycholoog"
                }, {
                  value: "DENTIST",
                  label: "Tandarts"
                }, {
                  value: "IMAGING_TECHNOLOGIST",
                  label: "Technoloog in de medischebeeldvorming"
                }, {
                  value: "IMPLANTPROVIDER",
                  label: "Verdeler van implantaten"
                }, {
                  value: "NURSE",
                  label: "Verpleegkundige"
                }, {
                  value: "MIDWIFE",
                  label: "Vroedvrouw"
                }, {
                  value: "PRACTICALNURSE",
                  label: "Zorgkundige"
                }
              ] : [
                {
                  value: "AMBU_SERVICE",
                  label: "Ambulancedienst"
                }, {
                  value: "PHARMACY",
                  label: "Apotheek"
                }, {
                  value: "TREATMENT_CENTER",
                  label: "Behandelingscentrum"
                }, {
                  value: "CONSORTIUM",
                  label: "Consortium kankerbevolkingsonderzoek"
                }, {
                  value: "CTRL_ORGANISM_EHP",
                  label: "Controle organisme"
                }, {
                  value: "LEGAL_PSY",
                  label: "Forensisch psychiatrisch centrum"
                }, {
                  value: "ICP",
                  label: "Geïntegreerd zorgproject"
                }, {
                  value: "HOME_SERVICES",
                  label: "Geïntegreerde diensten voor thuisverzorging"
                }, {
                  value: "OFFICE_DOCTORS",
                  label: "Groepering van huisartsen op dezelfde installatieplaats"
                }, {
                  value: "GROUP_DOCTORS",
                  label: "Groepering van huisartsen op verschillende installatieplaatsen"
                }, {
                  value: "GROUP",
                  label: "Groepering van verpleegkundigen"
                }, {
                  value: "PROT_ACC",
                  label: "Initiatieven van beschut wonen voor psychiatrische patiënten"
                }, {
                  value: "LABO",
                  label: "Laboratorium"
                }, {
                  value: "MEDICAL_HOUSE",
                  label: "Medisch huis"
                }, {
                  value: "INSTITUTION",
                  label: "Onderneming"
                }, {
                  value: "PALLIATIVE_CARE",
                  label: "Palliatieve Zorgen"
                }, {
                  value: "PSYCH_HOUSE",
                  label: "Psychiatrische verzorgingstehuis"
                }, {
                  value: "REEDUCATION",
                  label: "Rehabitalitatiehuis"
                }, {
                  value: "RETIREMENT",
                  label: "Rusthuis"
                }, {
                  value: "INSTITUTION_EHP",
                  label: "Speciale door het eHealth-platform erkende entiteit"
                }, {
                  value: "OTD_PHARMACY",
                  label: "Tariferingsdienst"
                }, {
                  value: "SORTING_CENTER",
                  label: "Triagecentrum"
                }, {
                  value: "GUARD_POST",
                  label: "Wachtpost"
                }, {
                  value: "HOSPITAL",
                  label: "Ziekenhuis"
                }
              ]).map(mm => <MenuItem value={mm.value}>{mm.label}</MenuItem>)}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={1} xs={12} md={6} sx={{alignContent: 'baseline', paddingLeft: 0.5}}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("shared.ehealth_mail.use_contact", "Bestaand contact gebruiken")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="filter"
              caption={t("shared.ehealth_mail.search.contact_name", "Filter op naam")}
              value={contactSearch || ''}
              onChange={(e) => {
                setContactSearch(e);
              }}
            />
          </Grid>
          {(eContacts || []).length === 0 && <Grid item xs={12}>
            <Box sx={{textAlign: 'center'}}>
              <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
            </Box>
          </Grid>}
          {(eContacts || []).length > 0 && (eContacts || []).map(contact => <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              borderColor: theme.palette.primary.main,
              padding: 1,
              borderRadius: 1
            }}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography>{contact.first_name} {contact.last_name}</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                  <Typography>{HelpersEhealth.get_quality(contact.quality)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{contact.entity_identifier}</Typography>
                </Grid>
                {!!contact.street && <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.8rem'}}>{contact.street}, {contact.zip} {contact.city}</Typography>
                </Grid>}
                <Grid item xs={12} sx={{textAlign: 'right'}}>
                  <Button
                    id="destroy"
                    destroyBtn
                    secondary
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      mutationDeleteContact.mutate({
                        formData: {
                          object: 'ehealth_contact',
                          id: contact.id,
                          ob: {
                            id: contact.id,
                          }
                        }
                      });
                    }}
                    size="small"
                    startIcon={<DeleteForeverIcon />}
                    label={t("shared.ehealth_mail.search.delete_contact", "Verwijderen")}
                  />
                  <Button
                    id="edit"
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      setNewContact(contact);
                    }}
                    size="small"
                    startIcon={<EditIcon />}
                    label={t("shared.ehealth_mail.search.edit_contact", "Wijzigen")}
                  />
                  <Button
                    contained
                    id="choose"
                    sx={{
                      marginLeft: 1,
                      marginTop: 0
                    }}
                    onClick={(e) => {
                      setCurrentMail({
                        ...currentMail,
                        extra_data: {
                          ...(currentMail.extra_data || {}),
                          draft_info: {
                            ...(currentMail.extra_data?.draft_info || {}),
                            attributes: {
                              ...(currentMail.extra_data?.draft_info?.attributes || {}),
                              to_ids: [
                                ...(currentMail.extra_data?.draft_info?.attributes.to_ids || []),
                                (contact.id || 0)
                              ]
                            }
                          }
                        }
                      });
                      setAddContact(false);
                    }}
                    size="small"
                    label={t("shared.ehealth_mail.search.use_contact", "Ontvanger toevoegen")}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>)}
          {(eContacts || []).length > 0 && <Grid item xs={12}>
            <Stack spacing={2} alignItems="center">
              <Pagination count={Math.ceil(count/10)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
            </Stack>
          </Grid>}
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setAddContact(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn disabled={
          !newContact?.first_name || 
          !newContact.entity_identifier || 
          !newContact.quality || 
          !newContact.entity_type
        } onClick={(e) => {
          mutationSave.mutate({
            formData: {
              object: 'ehealth_contact',
              id: newContact.id,
              ob: {
                ...newContact,
                user_id: login?.id
              },
              fields: ['id']
            },
            addContact: false
          });
        }}>{t("coach.general.actions.save")}</Btn>
        <Btn disabled={
          !newContact?.first_name || 
          !newContact.entity_identifier || 
          !newContact.quality || 
          !newContact.entity_type
        } onClick={(e) => {
          mutationSave.mutate({
            formData: {
              object: 'ehealth_contact',
              id: newContact.id,
              ob: {
                ...newContact,
                user_id: login?.id
              },
              fields: ['id']
            },
            addContact: true
          });
        }}>{t("coach.general.actions.save_and_add")}</Btn>
      </DialogActions>}
      open={addContact}
      setOpen={setAddContact}
    />
  </Page>;
}

