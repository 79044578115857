import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { loginAtom } from '../../lib/auth';
import { fetch_all_with_count, fetch_one } from "../../lib/v31lib";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import {
  Box,
  Grid
} from '@mui/material';

import {
  Button,
  Page,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { CardCoach } from '../../components/v2/cards/Coach';
import { SnackCopied } from '../../components/v2/snacks/Copied';

import { User } from '../../models/User';

const { DateTime } = require("luxon");

type Props = {};

export const AdminUserPage: FunctionComponent<Props> = () => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();

  // const [templates, setTemplates] = useState<User[]>([]);
  // const [templatesCount, setTemplatesCount] = useState<number>(0);
  // const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  // const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [search, setSearch] = useState<string>(!!state?.email ? state.email : '');
  const [realSearch, setRealSearch] = useState<string>(!!state?.email ? state.email : '');
  const [copyOpen, setCopyOpen] = useState<boolean>(false);
  const [passResetLinks, setPassResetLinks] = useState<{[z:string]: string}>({});
  const [passResetEmails, setPassResetEmails] = useState<{[z:string]: string}>({});
  const [mfaDone, setMfaDone] = useState<{[z:string]: string}>({});
  const [impDone, setImpDone] = useState<{[z:string]: string}>({});
  let count = 0;

  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError,
    isSuccess: usersSuccess,
    // refetch: usersRefetch,
  } = useQuery({
    queryKey: ["admin", 'users', {search: realSearch}],
    queryFn: () =>
      fetch_all_with_count<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'first_name', 'last_name', 'email', 'phone_fixed', 'phone_mobile', 'practice', 'settings', 'get_bg_medium', 'get_medium', 'type', 'is_super', 'is_secretary', 'mfa_status', 'enabled', 'last_activity'],
          sub_fields: {
            'practice': ['id', 'name', 'coaches', 'url_path'],
            'coaches': ['id', 'first_name', 'last_name', 'email', 'phone_fixed', 'phone_mobile', 'settings', 'get_bg_medium', 'get_medium', 'type', 'is_super', 'is_secretary', 'mfa_status', 'enabled', 'last_activity']
          },
          order: "type ASC, first_name ASC, last_name ASC",
          page: 0,
          per_page: 50,
          filter: {
            search: realSearch,
            advanced: {
              is_admin: 1,
              // enabled: '1'
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id && !!realSearch
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      pass?: boolean;
      mfa?: boolean;
      imp?: boolean;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.pass) {
        setPassResetLinks({
          ...passResetLinks,
          [variables.formData.id]: data.custom_result.url
        });
        setPassResetEmails({
          ...passResetEmails,
          [variables.formData.id]: data.custom_result.email
        });
      }
      if (variables.mfa) {
        setMfaDone({
          ...mfaDone,
          [variables.formData.id]: true
        });
      }
      if (variables.imp) {
        setImpDone({
          ...impDone,
          [variables.formData.id]: data.custom_result.url
        });
      }
    },
  });










  useEffect(() => {
    let to = setTimeout(() => {
      setRealSearch(search);
    }, 1000);

    return () => {
      clearTimeout(to);
    }
  }, [search]);
  
  return (
    <Page
      title="User Page"
    >
      <Grid container spacing={1}>
        {!selectedUser && <Grid item xs={12}>
          <TextInput
            id="search"
            caption="Search name, email, practice name"
            backend
            value={search}
            onChange={(e) => {
              setSearch(e);
            }}
          />
          <Typography sx={{fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.5)', fontSize: '0.9rem'}}>{count} results...</Typography>
        </Grid>}
        {!selectedUser && <Grid item xs={12}>
          {count < 51 && (users || []).map(user => <Box sx={{
            padding: 1,
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            display: 'inline-block',
            margin: 1,
            borderRadius: 1,
            cursor: 'pointer'
          }} onClick={() => {
            setSelectedUser(user);
          }}>
            <Typography>{user.first_name} {user.last_name} <span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>({user.type === "User::User" && "cliënt"}{user.type === "User::Coach" && "coach"}{user.type === "User::Admin" && "admin"})</span></Typography>
            <Typography>{user.email}</Typography>
            <Typography sx={{fontStyle: 'italic', fontSize: '0.9rem'}}>{user.practice?.name || '&nbsp;'}</Typography>
          </Box>)}
        </Grid>}




        {!!selectedUser && <>
          <Grid item xs={12} sx={{position: 'relative'}}>
            <Typography variant="h3">Selected user</Typography>
            <Button
              id="newsearch"
              contained
              onClick={() => {
                setSelectedUser(undefined);
              }}
              label="New search"
              secondary
              sx={{
                position: 'absolute',
                top: 1,
                right: 1
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardCoach
              coach={selectedUser}
              sx={{height: '400px', opacity: selectedUser.enabled ? 1 : 0.3}}
              withPractice
              withRole
              extraActions={<>
                <Grid container spacing={1}>
                  {!!selectedUser.practice?.url_path && <Grid item xs={12}>
                    <Typography sx={{fontSize: '0.8rem'}}>https://{selectedUser.practice?.url_path}.nutriportal.eu/ <ContentCopyIcon
                      sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(`https://${selectedUser.practice?.url_path}.nutriportal.eu/` || '');
                        setCopyOpen(true);
                      }}
                    /></Typography>
                  </Grid>}
                  {!passResetLinks[selectedUser.id?.toString() || ''] && <Grid item xs={12}>
                    <Button
                      id="passreset"
                      contained
                      onClick={() => {
                        mutationCustomAction.mutate({
                          formData: {
                            object: 'user',
                            class_action: 'custom_api_inquire_new_password',
                            id: selectedUser.id,
                            practice_id: selectedUser.practice?.id,
                            do_not_send: 1
                          },
                          pass: true
                        });
                      }}
                      label="Forgot Pass"
                      sx={{marginTop: 1}}
                    />
                  </Grid>}
                  {!!passResetLinks[selectedUser.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 2}}>
                    <Typography sx={{fontSize: '0.9rem'}}>Email to which it would go:</Typography>
                    <Typography sx={{fontSize: '0.8rem', paddingLeft: 2}}>{passResetEmails[selectedUser.id?.toString() || '']} <ContentCopyIcon
                      sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(passResetEmails[selectedUser.id?.toString() || ''] || '');
                        setCopyOpen(true);
                      }}
                    /></Typography>
                    <Typography sx={{fontSize: '0.9rem', marginTop: 1}}>URL:</Typography>
                    <Typography sx={{fontSize: '0.8rem', wordBreak: 'break-all', paddingLeft: 2}}>{passResetLinks[selectedUser.id?.toString() || '']} <ContentCopyIcon
                      sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(passResetLinks[selectedUser.id?.toString() || ''] || '');
                        setCopyOpen(true);
                      }}
                    /></Typography>
                  </Grid>}
                  {selectedUser.mfa_status === 1 && !mfaDone[selectedUser.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 1}}>
                    <Button
                      id="2fa_disable"
                      contained
                      secondary
                      destroyBtn
                      onClick={() => {
                        mutationCustomAction.mutate({
                          formData: {
                            object: 'user',
                            class_action: 'custom_api_mfa_actions',
                            id: selectedUser?.id,
                            mfa_action: 'disable'
                          },
                          mfa: true
                        });
                      }}
                      label={t("coach.general.buttons.disable_mfa")}
                      sx={{marginTop: 1}}
                    />
                  </Grid>}
                  {!impDone[selectedUser.id?.toString() || ''] && <Grid item xs={12}>
                    <Button
                      id="imp"
                      contained
                      onClick={() => {
                        mutationCustomAction.mutate({
                          formData: {
                            object: 'user',
                            class_action: 'custom_api_impersonate_url',
                            id: selectedUser.id
                          },
                          imp: true
                        });
                      }}
                      label="Impersonate URL"
                      sx={{marginTop: 1}}
                    />

                    {selectedUser.email.indexOf("accountclosed") < 0 && <Button
                      id="close_acc"
                      contained
                      startIcon={<PersonOffIcon />}
                      destroyBtn
                      color="secondary"
                      onClick={() => {
                        mutationCustomAction.mutate({
                          formData: {
                            object: 'user',
                            class_action: 'custom_api_close_acount',
                            id: selectedUser.id,
                          },
                          pass: true
                        });
                      }}
                      label="Close account"
                      sx={{marginTop: 1, float: 'right'}}
                    />}
                  </Grid>}
                  {!!impDone[selectedUser.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 2}}>
                    <Typography sx={{fontSize: '0.9rem', marginTop: 1}}>URL:</Typography>
                    <Typography sx={{fontSize: '0.8rem', wordBreak: 'break-all', paddingLeft: 2}}>{impDone[selectedUser.id?.toString() || '']} <ContentCopyIcon
                      sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(impDone[selectedUser.id?.toString() || ''] || '');
                        setCopyOpen(true);
                      }}
                    /></Typography>
                  </Grid>}
                  {!!selectedUser.last_activity && <Grid item xs={12} sx={{marginTop: 2}}>
                    <Typography sx={{fontSize: '0.7rem', marginTop: 1}}>Last activity: {DateTime.fromISO(selectedUser.last_activity).toFormat('dd/LL/y t')}</Typography>
                  </Grid>}
                </Grid>
              </>}
            />
          </Grid>

          {selectedUser.type === "User::Coach" && (selectedUser.practice?.coaches || []).length > 1 && <>
            <Grid item xs={12}>
              <Typography variant="h3">Others in practice</Typography>
            </Grid>
            {(selectedUser.practice?.coaches || []).map(usr => usr.id === selectedUser.id ? <></> : <>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <CardCoach
                  coach={usr}
                  sx={{height: '400px', opacity: usr.enabled ? 1 : 0.3}}
                  withPractice
                  withRole
                  extraActions={<>
                    <Grid container spacing={1}>
                      {!passResetLinks[usr.id?.toString() || ''] && <Grid item xs={12}>
                        <Button
                          id="passreset"
                          contained
                          onClick={() => {
                            mutationCustomAction.mutate({
                              formData: {
                                object: 'user',
                                class_action: 'custom_api_inquire_new_password',
                                id: usr.id,
                                practice_id: selectedUser.practice?.id,
                                do_not_send: 1
                              },
                              pass: true
                            });
                          }}
                          label="Forgot Pass"
                          sx={{marginTop: 1}}
                        />
                      </Grid>}
                      {!!passResetLinks[usr.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{fontSize: '0.9rem'}}>Email to which it would go:</Typography>
                        <Typography sx={{fontSize: '0.8rem', paddingLeft: 2}}>{passResetEmails[usr.id?.toString() || '']} <ContentCopyIcon
                          sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(passResetEmails[usr.id?.toString() || ''] || '');
                            setCopyOpen(true);
                          }}
                        /></Typography>
                        <Typography sx={{fontSize: '0.9rem', marginTop: 1}}>URL:</Typography>
                        <Typography sx={{fontSize: '0.8rem', wordBreak: 'break-all', paddingLeft: 2}}>{passResetLinks[usr.id?.toString() || '']} <ContentCopyIcon
                          sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(passResetLinks[usr.id?.toString() || ''] || '');
                            setCopyOpen(true);
                          }}
                        /></Typography>
                      </Grid>}
                      {usr.mfa_status === 1 && !mfaDone[usr.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 1}}>
                        <Button
                          id="2fa_disable"
                          contained
                          secondary
                          destroyBtn
                          onClick={() => {
                            mutationCustomAction.mutate({
                              formData: {
                                object: 'user',
                                class_action: 'custom_api_mfa_actions',
                                id: usr?.id,
                                mfa_action: 'disable'
                              },
                              mfa: true
                            });
                          }}
                          label={t("coach.general.buttons.disable_mfa")}
                          sx={{marginTop: 1}}
                        />
                      </Grid>}
                      {!impDone[usr.id?.toString() || ''] && <Grid item xs={12}>
                        <Button
                          id="imp"
                          contained
                          onClick={() => {
                            mutationCustomAction.mutate({
                              formData: {
                                object: 'user',
                                class_action: 'custom_api_impersonate_url',
                                id: usr.id
                              },
                              imp: true
                            });
                          }}
                          label="Impersonate URL"
                          sx={{marginTop: 1}}
                        />

                        {usr.email.indexOf("accountclosed") < 0 && <Button
                          id="close_acc"
                          contained
                          startIcon={<PersonOffIcon />}
                          destroyBtn
                          color="secondary"
                          onClick={() => {
                            mutationCustomAction.mutate({
                              formData: {
                                object: 'user',
                                class_action: 'custom_api_close_acount',
                                id: selectedUser.id,
                              },
                              pass: true
                            });
                          }}
                          label="Close account"
                          sx={{marginTop: 1, float: 'right'}}
                        />}
                      </Grid>}
                      {!!impDone[usr.id?.toString() || ''] && <Grid item xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{fontSize: '0.9rem', marginTop: 1}}>URL:</Typography>
                        <Typography sx={{fontSize: '0.8rem', wordBreak: 'break-all', paddingLeft: 2}}>{impDone[usr.id?.toString() || '']} <ContentCopyIcon
                          sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(impDone[usr.id?.toString() || ''] || '');
                            setCopyOpen(true);
                          }}
                        /></Typography>
                      </Grid>}
                      {!!usr.last_activity && <Grid item xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{fontSize: '0.7rem', marginTop: 1}}>Last activity: {DateTime.fromISO(usr.last_activity).toFormat('dd/LL/y t')}</Typography>
                      </Grid>}
                    </Grid>
                  </>}
                />
              </Grid>
            </>)}
          </>}
        </>}
      </Grid>

      <SnackCopied open={copyOpen} setOpen={setCopyOpen} />
    </Page>
  );
}
