import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import {
  Card,
  Dt,
  Page,
  Typography
} from '../../components/v2/styled';
import { PracticeLogoAvatar } from '../../components/v2/PracticeLogoAvatar';

import { NewsItem } from '../../models/NewsItem';
import { NutriMail } from '../../models/NutriMail';
import { Practice } from '../../models/Practice';
import { Workshop } from '../../models/Workshop';
import { User } from '../../models/User';

import HelpersPractice from '../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const RestrictedDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  











  return <Page title={`${t("restricted.dashboard.hello", "Hallo")} ${login?.first_name},`}>
    <Grid container spacing={2}>

      <Card
        // title={login?.practice_name}
      >
        <PracticeLogoAvatar
          showName
          practice={{get_medium: login?.practice_get_medium, name: login?.practice_name, item_is_square: login?.practice_item_is_square} as Practice}
        />
      </Card>

    </Grid>
  </Page>;
}
